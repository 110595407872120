import React from 'react';

import classNames from 'clsx';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import { formatDateDay, formatDateDayShortDayName, formatDateISO, isAfter } from '~/services/TimeService';

import Calendar from '../../Common/Calendar';
import BlackoutDateForm from '../../Common/Forms/BlackoutDateForm';
import InventoryForm from '../../Common/Forms/InventoryForm';
import SurchargeDateForm from '../../Common/Forms/SurchargeDateForm';
import convertDateListToRanges from '../../Common/Forms/helpers/convertDateListToRanges';
import Selection from '../../Common/Selection';

interface DayProps {
  dayDate: any;
  idx: number;
}

interface DayState {
  selected: boolean;
}

class CalendarDay extends React.Component<DayProps, DayState> {
  date: any;
  count?: number;
  total?: number;
  constructor(props) {
    super(props);

    this.state = {
      selected: false,
    };
    this.count = props.dayDate.availability?.count;
    this.total = props.dayDate.availability?.total;
    this.date = props.dayDate.day;
  }

  setSelected(selected: boolean) {
    this.setState({ selected });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dayDate.availability?.count !== this.props.dayDate.availability?.count) {
      this.count = this.props.dayDate.availability?.count;
    }

    if (prevProps.dayDate.availability?.total !== this.props.dayDate.availability?.total) {
      this.total = this.props.dayDate.availability?.total;
    }
  }

  render() {
    const { dayDate, idx } = this.props;
    const formatDay = (m) => formatDateDay(m);
    const dayClasses = dayDate.day
      ? [`day-${formatDateISO(dayDate.day)}`, `day-${formatDateDayShortDayName(dayDate.day).toLowerCase()}`]
      : null;

    const classes = classNames('availability-day', dayClasses, {
      'select-box': !!dayDate.day,
      blackout: dayDate.blackout,
      surcharge: dayDate.surcharge,
      selected: this.state.selected,
    });

    return (
      <div className={classes} key={idx}>
        {dayDate.day && (
          <div className="date">
            {formatDay(dayDate.day)}
            {dayDate.surcharge && <sup className="surcharge-marker"> +</sup>}
          </div>
        )}
        {dayDate.availability && (
          <div className="counts">
            {dayDate.availability.count} / {dayDate.availability.total}
          </div>
        )}
      </div>
    );
  }
}

interface RoomTypeDatesEditProps {
  grid: any;
  vendorId: string;
  vendor: any;
  propertyId: string;
  roomType: any;
  roomRate: any;
  updateAvailability: any;
  propertyRoomTypes: any;
  channelManaged: boolean;
  addSurchargeDateBlocks: any;
  surchargeSchema: any;
  fxRates: any;
  addBlackoutDateBlocks: any;
}

interface RoomTypeDatesEditState {
  settingInventory: boolean;
  selectedDates: Array<any>;
  selectMode: string;
  addingBlackout: boolean;
  addingSurcharge: boolean;
  selectedRange: any;
}

class RoomTypeDatesEdit extends React.Component<RoomTypeDatesEditProps, RoomTypeDatesEditState> {
  selectedDayIndices = [];
  selectComponent: any;
  calendar: any;

  constructor(props) {
    super(props);

    this.state = {
      settingInventory: false,
      selectedDates: [],
      selectMode: 'free',
      addingBlackout: false,
      addingSurcharge: false,
      selectedRange: {},
    };
  }

  componentDidMount() {
    this.selectComponent.setSelectableRefs(this.calendar.days);
  }

  componentDidUpdate() {
    if (this.state.selectedDates.length > 0 && this.calendar?.days) {
      const updateDays = this.state.selectedDates
        .map((x) => {
          const day = this.calendar.days.find((d) => d.date === x.date);
          if (day) {
            if (day.total !== x.total) {
              return {
                ...x,
                total: day.total,
              };
            }
          }
        })
        .filter(Boolean);

      if (updateDays.length > 0) {
        this.setState({
          selectedDates: updateDays,
        });
      }
    }
  }

  onSelectionChange = (selections) => {
    if (this.props.channelManaged) {
      return;
    }

    const newSelectedDayIndices = selections.map((a) => parseInt(a));

    this.selectedDayIndices.forEach((dayIndex) => {
      if (!newSelectedDayIndices.includes(dayIndex)) {
        this.calendar.days[dayIndex].setSelected(false);
      }
    });

    newSelectedDayIndices.forEach((dayIndex) => {
      this.calendar.days[dayIndex].setSelected(true);
    });

    const selectedDates = [];
    selections.forEach((day) => {
      selectedDates.push({
        date: this.calendar.days[day].date,
        count: this.calendar.days[day].count,
        total: this.calendar.days[day].total,
      });
    });
    this.setState({
      selectedDates,
    });

    this.selectedDayIndices = newSelectedDayIndices;
  };

  showInventoryForm = () => {
    if (this.state.selectedDates.length > 0) {
      this.setState({
        settingInventory: true,
      });
    }
  };

  hideInventoryForm = () => {
    this.setState({ settingInventory: false });
  };

  showBlackoutForm = () => {
    const ranges = convertDateListToRanges(this.state.selectedDates);
    if (this.state.selectMode === 'range' && ranges[0]) {
      this.setState({
        addingBlackout: true,
        selectedRange: ranges[0],
      });
    }
  };

  hideBlackoutForm = () => {
    this.setState({ addingBlackout: false });
  };

  showSurchargeForm = () => {
    const ranges = convertDateListToRanges(this.state.selectedDates);
    if (this.state.selectMode === 'range' && ranges[0]) {
      this.setState({
        addingSurcharge: true,
        selectedRange: ranges[0],
      });
    }
  };

  hideSurchargeForm = () => {
    this.setState({ addingSurcharge: false });
  };

  setSelectMode = (event) => {
    this.setState({ selectMode: event.target.value });
  };

  dayCompareFunc(a, b) {
    if (isAfter(b.date, a.date)) {
      return -1;
    }
    if (isAfter(a.date, b.date)) {
      return 1;
    }
    return 0;
  }

  render() {
    const {
      grid,
      vendorId,
      vendor,
      propertyId,
      roomType,
      roomRate,
      updateAvailability,
      propertyRoomTypes,
      channelManaged,
      addBlackoutDateBlocks,
      fxRates,
      addSurchargeDateBlocks,
      surchargeSchema,
    } = this.props;
    const { settingInventory, selectedDates, selectMode, addingBlackout, addingSurcharge, selectedRange } = this.state;
    const onlyEmptyCellSelected = selectedDates.every((date) => !date.total);

    const roomRatePath = `/vendors/${vendorId}/properties/${propertyId}/room-types/${roomType.id}/room-rates/${roomRate.id}`;

    return (
      <>
        <Helmet>
          <title>
            Vendor | {vendor?.name || vendorId} | {roomType.name} - {roomRate.rate_plan.name}
          </title>
        </Helmet>

        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: 'white',
            padding: 2,
            borderBottom: '1px solid #e0e0e0',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Link to={roomRatePath}>
            <Button startIcon={<ArrowBackIosIcon />} variant="text">
              Return to room rate
            </Button>
          </Link>
          <Stack mt={2} direction="row" justifyContent="space-between">
            <Stack>
              <Typography variant="caption">Room Type - Rate Plan</Typography>
              <Typography variant="h5">
                {roomType.name} - {roomRate.rate_plan.name}
              </Typography>
              {channelManaged && (
                <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                  *Availability controlled by channel manager, e.g. Siteminder
                </Typography>
              )}
            </Stack>

            {!channelManaged && (
              <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                <Typography variant="h6" mr={2}>
                  Select mode:
                </Typography>
                <FormControl>
                  <RadioGroup row>
                    <FormControlLabel
                      name="selectmode"
                      value="free"
                      checked={selectMode === 'free'}
                      onClick={this.setSelectMode}
                      control={<Radio />}
                      label="Free select"
                    />
                    <FormControlLabel
                      name="selectmode"
                      value="range"
                      checked={selectMode === 'range'}
                      onClick={this.setSelectMode}
                      control={<Radio />}
                      label="Range"
                    />
                  </RadioGroup>
                </FormControl>
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                  <Button onClick={this.showInventoryForm} className="apply-inventory-button" size="small">
                    Set inventory
                  </Button>
                  <Tooltip
                    title="Blackouts can only be applied in range mode"
                    disableHoverListener={selectMode === 'range'}
                  >
                    <Button
                      onClick={this.showBlackoutForm}
                      sx={{
                        cursor: selectMode !== 'range' ? 'not-allowed' : 'pointer',
                        opacity: selectMode !== 'range' ? 0.6 : 1,
                      }}
                      size="small"
                    >
                      Set blackout
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title="Surcharges can only be applied in range mode"
                    disableHoverListener={selectMode === 'range'}
                  >
                    <Button
                      onClick={this.showSurchargeForm}
                      sx={{
                        cursor: selectMode !== 'range' ? 'not-allowed' : 'pointer',
                        opacity: selectMode !== 'range' ? 0.6 : 1,
                      }}
                      size="small"
                    >
                      Set surcharge
                    </Button>
                  </Tooltip>
                </ButtonGroup>
              </Stack>
            )}
          </Stack>
        </Box>

        <Box m={2}>
          <Selection
            ref={(a) => (this.selectComponent = a)}
            onSelectionChange={this.onSelectionChange}
            selectMode={selectMode}
            rangeCompareFunc={this.dayCompareFunc}
          >
            <Calendar grid={grid} calendarDayComponent={CalendarDay} ref={(a) => (this.calendar = a)} />
          </Selection>

          <Dialog
            open={settingInventory}
            onClose={this.hideInventoryForm}
            maxWidth={onlyEmptyCellSelected ? 'sm' : 'md'}
            fullWidth
          >
            <DialogTitle>Apply Inventory</DialogTitle>
            <DialogContent>
              <InventoryForm
                dates={selectedDates}
                onlyEmptyCellSelected={onlyEmptyCellSelected}
                propertyId={propertyId}
                roomType={roomType}
                propertyRoomTypes={propertyRoomTypes}
                onCancel={this.hideInventoryForm}
                onInventoryUpdated={updateAvailability}
                onSuccess={this.hideInventoryForm}
              />
            </DialogContent>
          </Dialog>

          <Dialog open={addingBlackout} onClose={this.hideBlackoutForm}>
            <DialogTitle>Apply Blackout Dates</DialogTitle>
            <DialogContent>
              <BlackoutDateForm
                propertyId={propertyId}
                roomType={roomType}
                roomRate={roomRate}
                propertyRoomTypes={propertyRoomTypes}
                onCancel={this.hideBlackoutForm}
                onBlackoutDateBlocksAdded={addBlackoutDateBlocks}
                onSuccess={this.hideBlackoutForm}
                startDate={selectedRange.start}
                endDate={selectedRange.end}
              />
            </DialogContent>
          </Dialog>

          <Dialog open={addingSurcharge} onClose={this.hideSurchargeForm}>
            <DialogTitle>Apply Surcharge Dates</DialogTitle>
            <DialogContent>
              <SurchargeDateForm
                propertyId={propertyId}
                roomType={roomType}
                roomRate={roomRate}
                propertyRoomTypes={propertyRoomTypes}
                onCancel={this.hideSurchargeForm}
                vendorCurrencyCode={vendor.currencyisocode}
                onSurchargeDateBlocksAdded={addSurchargeDateBlocks}
                onSuccess={this.hideSurchargeForm}
                startDate={selectedRange.start}
                endDate={selectedRange.end}
                surchargeSchema={surchargeSchema}
                foreignExchangeRates={fxRates}
              />
            </DialogContent>
          </Dialog>
        </Box>
      </>
    );
  }
}

export default RoomTypeDatesEdit;
