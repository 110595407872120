import React, { useMemo } from 'react';

import { sumBy } from 'lodash';

import ListIcon from '@mui/icons-material/ListAlt';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';

import { formatDateOrdinalShortMonth } from '~/services/TimeService';

import { Payment } from '~/types/services/payment';

import { mapPendingPointsForItem, mapPointsPaymentsForItem } from '~/utils/luxLoyalty';

import LuxLoyaltyEarnBreakdown from './LuxLoyaltyEarnBreakdown';
import LuxLoyaltyNumNights from './LuxLoyaltyNumNights';
import LuxLoyaltyPointsDetails from './LuxLoyaltyOrderPointsDetails';

interface Props {
  payments: Array<Payment>;
  pendingPoints: Array<App.LuxLoyaltyPendingPoint>;
  accruals: Array<App.LuxLoyaltyAccrual>;
  order?: App.Order;
}

const pointsBenefitPayments: Array<App.LuxLoyaltyBenefitPaymentType> = [
  'le_points_airport_transfer',
  'le_points_discounted_insurance',
  'le_points_hotel_upgrade',
  'le_points_lounge_pass',
  'le_points_tour_optional_extra_voucher',
  'le_points_travelshoot_voucher',
];

export default function OrderDetailLuxLoyalty({ payments, pendingPoints, order, accruals }: Props) {
  const orderPointsPaymentsBreakdown = useMemo(() => {
    if (!payments || !Array.isArray(payments)) {
      return {
        totalPointsSpent: 0,
        totalPointsRefunded: 0,
        pointsPaymentCurrency: undefined,
      };
    }
    const pointsPayments = payments.filter((payment) => payment.type === 'le_points');
    const totalPointsSpent = pointsPayments.reduce((prev, acc) => {
      return acc.intent === 'sale' ? prev + Number(acc.amount) : prev;
    }, 0);
    const totalPointsRefunded = pointsPayments.reduce((prev, acc) => {
      return acc.intent === 'refund' ? prev + Number(acc.amount) : prev;
    }, 0);
    return {
      totalPointsSpent,
      totalPointsRefunded,
      pointsPaymentCurrency: pointsPayments.length ? pointsPayments[0].currency : undefined,
    };
  }, [payments]);

  // TODO benefits payment dependent on other tasks

  const orderPendingPointsBreakdown = useMemo(() => {
    if (!pendingPoints || !Array.isArray(pendingPoints)) {
      return;
    }
    const totalPendingPoints = sumBy(pendingPoints, 'points');
    const totalPendingStatusCredits = sumBy(pendingPoints, 'statusCredits');
    const cancelledPendingPoints = pendingPoints.reduce((prev, curr) => {
      return curr.status === 'CANCELLED' ? prev + curr.points : 0;
    }, 0);
    const cancelledPendingStatusCredits = pendingPoints.reduce((prev, curr) => {
      return curr.status === 'CANCELLED' ? prev + curr.statusCredits : 0;
    }, 0);

    return {
      totalPendingPoints,
      totalPendingStatusCredits,
      cancelledPendingPoints,
      cancelledPendingStatusCredits,
    };
  }, [pendingPoints]);

  if (!order || !payments.length) {
    return null;
  }

  return (
    <Container>
      <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
        Order Summary
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="h6" gutterBottom>
              Points Activity{' '}
              {orderPointsPaymentsBreakdown.pointsPaymentCurrency &&
                `(${orderPointsPaymentsBreakdown.pointsPaymentCurrency})`}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>Points Spent: {orderPointsPaymentsBreakdown.totalPointsSpent.toLocaleString()}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Points Refunded: {orderPointsPaymentsBreakdown.totalPointsRefunded.toLocaleString()}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography fontWeight="bold">
                  Net Points Used:{' '}
                  {(
                    orderPointsPaymentsBreakdown.totalPointsSpent - orderPointsPaymentsBreakdown.totalPointsRefunded
                  ).toLocaleString()}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="h6" gutterBottom>
              Pending Points
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>
                  Pending Points: {orderPendingPointsBreakdown.totalPendingPoints.toLocaleString()}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Pending Status Credits: {orderPendingPointsBreakdown.totalPendingStatusCredits.toLocaleString()}
                </Typography>
              </Grid>
              {(orderPendingPointsBreakdown.cancelledPendingPoints > 0 ||
                orderPendingPointsBreakdown.cancelledPendingStatusCredits > 0) && (
                <Grid item xs={12}>
                  <Box>
                    <Typography>
                      Cancelled Points: {orderPendingPointsBreakdown.cancelledPendingPoints.toLocaleString()}
                    </Typography>
                    <Typography>
                      Cancelled Status Credits:{' '}
                      {orderPendingPointsBreakdown.cancelledPendingStatusCredits.toLocaleString()}
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>

        {!!order.subscription_items?.length && (
          <Grid item xs={12}>
            <Box>
              <Typography variant="h6" gutterBottom>
                Subscription Items
              </Typography>
              {order.subscription_items.map((item) => {
                const pointsPayment = mapPointsPaymentsForItem(payments, item.id);
                const pendingPointsInfo = mapPendingPointsForItem(pendingPoints, item.id);
                const itemAccruals = findAccrualsForItem(item.id, accruals);

                return (
                  <Box key={item.id} sx={{ mb: 3, p: 2, border: 1, borderColor: 'divider', borderRadius: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Stack spacing={1}>
                          <Stack>
                            <Typography variant="subtitle1">
                              <Typography sx={{ display: 'inline', color: '#304DAB', fontWeight: 'bold' }}>
                                Lux Plus+{' '}
                              </Typography>
                              {item.sub_type === 'recurring_fee' && 'Subscription Fee'}
                              {item.sub_type === 'joining_fee' && 'Joining Fee'}
                            </Typography>
                          </Stack>

                          <Stack direction="row" spacing={4}>
                            <Stack>
                              <Typography variant="caption">Status</Typography>
                              <Typography>{item.status.toUpperCase()}</Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <LuxLoyaltyPointsDetails
                          pointsPayment={pointsPayment}
                          pendingPointsInfo={pendingPointsInfo}
                          region={order.region_code}
                        />
                      </Grid>
                    </Grid>
                    {item.status === 'completed' && (
                      <LuxLoyaltyEarnBreakdown
                        item={item}
                        order={order}
                        itemAccruals={itemAccruals}
                        payments={payments}
                      />
                    )}
                  </Box>
                );
              })}
            </Box>
          </Grid>
        )}

        {order.bedbank_items.length > 0 && (
          <Grid item xs={12}>
            <Box>
              <Typography variant="h6" gutterBottom>
                Accommodation Items
              </Typography>
              {order.bedbank_items.map((item) => {
                const pointsPayment = mapPointsPaymentsForItem(payments, item.id);
                const pendingPointsInfo = mapPendingPointsForItem(pendingPoints, item.id);
                const itemAccruals = findAccrualsForItem(item.id, accruals);

                return (
                  <Box key={item.id} sx={{ mb: 3, p: 2, border: 1, borderColor: 'divider', borderRadius: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Stack spacing={1}>
                          <Typography variant="subtitle1">{item.offer.name}</Typography>
                          <LuxLoyaltyNumNights startDate={item.check_in} endDate={item.check_out} />
                          {item.booking_number && (
                            <Stack direction="row" spacing={1}>
                              <ListIcon fontSize="small" />
                              <Typography>Booking: {item.booking_number}</Typography>
                            </Stack>
                          )}
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <LuxLoyaltyPointsDetails
                          pointsPayment={pointsPayment}
                          pendingPointsInfo={pendingPointsInfo}
                          region={order.region_code}
                        />
                      </Grid>
                    </Grid>
                    {item.status === 'completed' && (
                      <LuxLoyaltyEarnBreakdown
                        item={item}
                        order={order}
                        itemAccruals={itemAccruals}
                        payments={payments}
                      />
                    )}
                  </Box>
                );
              })}
            </Box>
          </Grid>
        )}

        {!!order.tour_items?.length && (
          <Grid item xs={12}>
            <Box>
              <Typography variant="h6" gutterBottom>
                Tour Items
              </Typography>
              {order.tour_items.map((item) => {
                const pointsPayment = mapPointsPaymentsForItem(payments, item.id);
                const pendingPointsInfo = mapPendingPointsForItem(pendingPoints, item.id);
                const itemAccruals = findAccrualsForItem(item.id, accruals);

                return (
                  <Box key={item.id} sx={{ mb: 3, p: 2, border: 1, borderColor: 'divider', borderRadius: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Stack spacing={1}>
                          <Typography variant="subtitle1">{item.tour_name}</Typography>
                          <Typography>Booking: {item.booking_number}</Typography>
                          <LuxLoyaltyNumNights
                            startDate={new Date(item.start_date).toISOString()}
                            endDate={new Date(item.end_date).toISOString()}
                          />
                          <Stack direction="row" spacing={4}>
                            <Stack>
                              <Typography variant="caption">Status</Typography>
                              <Typography>{item.status.toUpperCase()}</Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <LuxLoyaltyPointsDetails
                          pointsPayment={pointsPayment}
                          pendingPointsInfo={pendingPointsInfo}
                          region={order.region_code}
                        />
                      </Grid>
                    </Grid>
                    {item.status === 'completed' && (
                      <LuxLoyaltyEarnBreakdown
                        item={item}
                        order={order}
                        itemAccruals={itemAccruals}
                        payments={payments}
                      />
                    )}
                  </Box>
                );
              })}
            </Box>
          </Grid>
        )}

        {!!order.tour_optional_experience_items?.length && (
          <Grid item xs={12}>
            <Box>
              <Typography variant="h6" gutterBottom>
                Tour Optional Experience Items
              </Typography>
              {order.tour_optional_experience_items.map((item) => {
                const pointsPayment = mapPointsPaymentsForItem(payments, item.id);
                const pendingPointsInfo = mapPendingPointsForItem(pendingPoints, item.id);
                const itemAccruals = findAccrualsForItem(item.id, accruals);

                return (
                  <Box key={item.id} sx={{ mb: 3, p: 2, border: 1, borderColor: 'divider', borderRadius: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Stack spacing={1}>
                          <Typography variant="subtitle1">{item.name}</Typography>
                          <Stack direction="row" spacing={4}>
                            <Stack>
                              <Typography variant="caption">Status</Typography>
                              <Typography>{item.status.toUpperCase()}</Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <LuxLoyaltyPointsDetails
                          pointsPayment={pointsPayment}
                          pendingPointsInfo={pendingPointsInfo}
                          region={order.region_code}
                        />
                      </Grid>
                    </Grid>
                    {item.status === 'completed' && (
                      <LuxLoyaltyEarnBreakdown
                        item={item}
                        order={order}
                        itemAccruals={itemAccruals}
                        payments={payments}
                      />
                    )}
                  </Box>
                );
              })}
            </Box>
          </Grid>
        )}

        {order.car_hire_items.length > 0 && (
          <Grid item xs={12}>
            <Box>
              <Typography variant="h6" gutterBottom>
                Car Hire Items
              </Typography>
              {order.car_hire_items.map((item) => {
                const pointsPayment = mapPointsPaymentsForItem(payments, item.id);
                const pendingPointsInfo = mapPendingPointsForItem(pendingPoints, item.id);
                const itemAccruals = findAccrualsForItem(item.id, accruals);

                return (
                  <Box key={item.id} sx={{ mb: 3, p: 2, border: 1, borderColor: 'divider', borderRadius: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Stack spacing={1}>
                          <Stack direction="row" spacing={4}>
                            <Stack>
                              <Typography variant="caption">Status</Typography>
                              <Typography>{item.status.toUpperCase()}</Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <LuxLoyaltyPointsDetails
                          pointsPayment={pointsPayment}
                          pendingPointsInfo={pendingPointsInfo}
                          region={order.region_code}
                        />
                      </Grid>
                    </Grid>
                    {item.status === 'completed' && (
                      <LuxLoyaltyEarnBreakdown
                        item={item}
                        order={order}
                        itemAccruals={itemAccruals}
                        payments={payments}
                      />
                    )}
                  </Box>
                );
              })}
            </Box>
          </Grid>
        )}

        {order.flight_items?.length > 0 && (
          <Grid item xs={12}>
            <Box>
              <Typography variant="h6" gutterBottom>
                Flight Items
              </Typography>
              {order.flight_items
                ?.sort((a, b) => (a.leg_number ?? 0) - (b.leg_number ?? 0))
                .map((item) => {
                  const pointsPayment = mapPointsPaymentsForItem(payments, item.id);
                  const pendingPointsInfo = mapPendingPointsForItem(pendingPoints, item.id);
                  const itemAccruals = findAccrualsForItem(item.id, accruals);

                  return (
                    <Box key={item.id} sx={{ mb: 3, p: 2, border: 1, borderColor: 'divider', borderRadius: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Stack spacing={1}>
                            <Stack direction="row" spacing={4}>
                              <Stack>
                                <Typography variant="caption">Provider PNR</Typography>
                                <Typography>{item.pnr_id}</Typography>
                              </Stack>
                              <Stack>
                                <Typography variant="caption">Provider</Typography>
                                <Typography>{item.provider}</Typography>
                              </Stack>
                              <Stack>
                                <Typography variant="caption">Status</Typography>
                                <Typography>{item.status.toUpperCase()}</Typography>
                              </Stack>
                              {item.refund_status && (
                                <Stack>
                                  <Typography variant="caption">Refund Status</Typography>
                                  <Typography>{item.refund_status}</Typography>
                                </Stack>
                              )}
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item xs={6}>
                          <LuxLoyaltyPointsDetails
                            pointsPayment={pointsPayment}
                            pendingPointsInfo={pendingPointsInfo}
                            region={order.region_code}
                          />
                        </Grid>
                      </Grid>
                      {item.status === 'completed' && (
                        <LuxLoyaltyEarnBreakdown
                          item={item}
                          order={order}
                          itemAccruals={itemAccruals}
                          payments={payments}
                        />
                      )}
                    </Box>
                  );
                })}
            </Box>
          </Grid>
        )}

        {order.insurance_items?.length > 0 && (
          <Grid item xs={12}>
            <Box>
              <Typography variant="h6" gutterBottom>
                Insurance Items
              </Typography>
              {order.insurance_items.map((item) => {
                const pointsPayment = mapPointsPaymentsForItem(payments, item.id);
                const pendingPointsInfo = mapPendingPointsForItem(pendingPoints, item.id);
                const itemAccruals = findAccrualsForItem(item.id, accruals);

                return (
                  <Box key={item.id} sx={{ mb: 3, p: 2, border: 1, borderColor: 'divider', borderRadius: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Stack spacing={1}>
                          <Typography variant="subtitle1">{item.product_name}</Typography>

                          <Stack direction="row" spacing={2}>
                            <Stack>
                              <Typography>Policy IDs</Typography>
                              <Typography>{item.policy_ids?.join(', ')}</Typography>
                            </Stack>
                            <Stack>
                              <Typography>Provider</Typography>
                              <Typography>{item.provider}</Typography>
                            </Stack>
                          </Stack>

                          <Stack direction="row" spacing={2}>
                            <Stack>
                              <Typography>Start Date</Typography>
                              <Typography>{formatDateOrdinalShortMonth(new Date(item.start_date))}</Typography>
                            </Stack>
                            <Stack>
                              <Typography>End Date</Typography>
                              <Typography>{formatDateOrdinalShortMonth(new Date(item.end_date))}</Typography>
                            </Stack>
                          </Stack>

                          <Stack direction="row" spacing={2}>
                            <Stack>
                              <Typography>Status</Typography>
                              <Typography>{item.status.toUpperCase()}</Typography>
                            </Stack>
                            {item.claim_status && (
                              <Stack>
                                <Typography>Claim Status</Typography>
                                <Typography>{item.claim_status}</Typography>
                              </Stack>
                            )}
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <LuxLoyaltyPointsDetails
                          pointsPayment={pointsPayment}
                          pendingPointsInfo={pendingPointsInfo}
                          region={order.region_code}
                        />
                      </Grid>
                    </Grid>
                    {item.status === 'completed' && (
                      <LuxLoyaltyEarnBreakdown
                        item={item}
                        order={order}
                        itemAccruals={itemAccruals}
                        payments={payments}
                      />
                    )}
                  </Box>
                );
              })}
            </Box>
          </Grid>
        )}

        {!!order.cruise_items.length && (
          <Grid item xs={12}>
            <Box>
              <Typography variant="h6" gutterBottom>
                Cruise Items
              </Typography>
              {order.cruise_items.map((item) => {
                const pointsPayment = mapPointsPaymentsForItem(payments, item.id);
                const pendingPointsInfo = mapPendingPointsForItem(pendingPoints, item.id);
                const itemAccruals = findAccrualsForItem(item.id, accruals);

                return (
                  <Box key={item.id} sx={{ mb: 3, p: 2, border: 1, borderColor: 'divider', borderRadius: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Stack spacing={1}>
                          <Typography variant="subtitle1">{item.cruise_line}</Typography>
                          <Typography>{item.ship_name}</Typography>
                          <LuxLoyaltyNumNights startDate={item.departure_date} endDate={item.arrival_date} />
                          {item.booking_number && (
                            <Stack direction="row" spacing={1}>
                              <ListIcon fontSize="small" />
                              <Typography>Booking: {item.booking_number}</Typography>
                            </Stack>
                          )}
                          <Typography>Status: {item.status}</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <LuxLoyaltyPointsDetails
                          pointsPayment={pointsPayment}
                          pendingPointsInfo={pendingPointsInfo}
                          region={order.region_code}
                        />
                      </Grid>
                    </Grid>
                    {item.status === 'completed' && (
                      <LuxLoyaltyEarnBreakdown
                        item={item}
                        order={order}
                        itemAccruals={itemAccruals}
                        payments={payments}
                      />
                    )}
                  </Box>
                );
              })}
            </Box>
          </Grid>
        )}

        {order.experience_items?.length > 0 && (
          <Grid item xs={12}>
            <Box>
              <Typography variant="h6" gutterBottom>
                Experience Items
              </Typography>
              {order.experience_items.map((item) => {
                const pointsPayment = mapPointsPaymentsForItem(payments, item.id);
                const pendingPointsInfo = mapPendingPointsForItem(pendingPoints, item.id);
                const itemAccruals = findAccrualsForItem(item.id, accruals);

                return (
                  <Box key={item.id} sx={{ mb: 3, p: 2, border: 1, borderColor: 'divider', borderRadius: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Stack spacing={1}>
                          <Typography variant="subtitle1">{item.title}</Typography>

                          <Stack direction="row" spacing={2}>
                            <Stack>
                              <Typography variant="caption">Fare Type</Typography>
                              <Typography>{item.ticket.fareType}</Typography>
                            </Stack>
                            {item.booking_number && (
                              <Stack>
                                <Typography variant="caption">Booking</Typography>
                                <Typography>{item.booking_number}</Typography>
                              </Stack>
                            )}
                            <Stack>
                              <Typography variant="caption">Status</Typography>
                              <Typography>{item.status.toUpperCase()}</Typography>
                            </Stack>
                          </Stack>

                          {item.provider && (
                            <Stack direction="row" spacing={1}>
                              <ListIcon fontSize="small" />
                              <Typography>Provider: {item.provider}</Typography>
                            </Stack>
                          )}
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <LuxLoyaltyPointsDetails
                          pointsPayment={pointsPayment}
                          pendingPointsInfo={pendingPointsInfo}
                          region={order.region_code}
                        />
                      </Grid>
                    </Grid>
                    {item.status === 'completed' && (
                      <LuxLoyaltyEarnBreakdown
                        item={item}
                        order={order}
                        itemAccruals={itemAccruals}
                        payments={payments}
                      />
                    )}
                  </Box>
                );
              })}
            </Box>
          </Grid>
        )}

        {order.accommodation_items?.length > 0 && (
          <Grid item xs={12}>
            <Box>
              <Typography variant="h6" gutterBottom>
                Accommodation Items
              </Typography>
              {order.accommodation_items.map((item) => {
                const pointsPayment = mapPointsPaymentsForItem(payments, item.id);
                const pendingPointsInfo = mapPendingPointsForItem(pendingPoints, item.id);
                const offer = item.offer;
                const itemAccruals = findAccrualsForItem(item.id, accruals);

                return (
                  <Box key={item.id} sx={{ mb: 3, p: 2, border: 1, borderColor: 'divider', borderRadius: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Stack spacing={1}>
                          <Typography variant="subtitle1">{offer.name}</Typography>
                          <Typography>ID: {offer.id_salesforce_external}</Typography>

                          <LuxLoyaltyNumNights
                            startDate={item.reservation.check_in}
                            endDate={item.reservation.check_out}
                            numNights={item.number_of_nights}
                          />

                          {item.booking_number && (
                            <Stack direction="row" spacing={1}>
                              <ListIcon fontSize="small" />
                              <Typography>Booking: {item.booking_number}</Typography>
                            </Stack>
                          )}
                          <Typography>Status: {item.status.toUpperCase()}</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <LuxLoyaltyPointsDetails
                          pointsPayment={pointsPayment}
                          pendingPointsInfo={pendingPointsInfo}
                          region={order.region_code}
                        />
                      </Grid>
                    </Grid>
                    {item.status === 'completed' && (
                      <LuxLoyaltyEarnBreakdown
                        item={item}
                        order={order}
                        itemAccruals={itemAccruals}
                        payments={payments}
                      />
                    )}
                  </Box>
                );
              })}
            </Box>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

function findAccrualsForItem(itemId: string, accruals: Array<App.LuxLoyaltyAccrual>) {
  if (!accruals || !Array.isArray(accruals)) {
    return [];
  }
  return accruals.filter((acc) => acc.itemId === itemId);
}
