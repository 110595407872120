import React from 'react';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Stack, Typography } from '@mui/material';

import { formatDateOrdinalShortMonth, getTimeDifference } from '~/services/TimeService';

interface Props {
  startDate: string;
  endDate: string;
  numNights?: number;
}

export default function LuxLoyaltyNumNights({ startDate, endDate, numNights }: Props) {
  return (
    <>
      <Stack direction="row" spacing={1}>
        <CalendarMonthIcon fontSize="small" />
        <Typography>
          {formatDateOrdinalShortMonth(new Date(startDate))} - {formatDateOrdinalShortMonth(new Date(endDate))}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <CalendarMonthIcon fontSize="small" />
        <Typography>{numNights ?? getTimeDifference(new Date(startDate), new Date(endDate))}</Typography>
      </Stack>
    </>
  );
}
