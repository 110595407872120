import React, { useState } from 'react';

import { useSnackbar } from 'notistack';

import { Stack } from '@mui/material';

import { Payment } from '~/types/services/payment';

import { EarnButton } from './EarnButton';
import LuxLoyaltyManualEarnTable from './LuxLoyaltyManualEarnTable';
import { AccrualOp, LuxLoyaltyEarnEligibleOrderItem, manualEarnLuxLoyaltyPoints } from './manualEarn.service';

interface Props {
  order: App.Order;
  item: LuxLoyaltyEarnEligibleOrderItem;
  payments: Array<Payment>;
}

const INITIAL_POINTS_EARN_RESULT = {
  pending: {
    points: 0,
    statusCredits: 0,
  },
  estimate: {
    points: 0,
    statusCredits: 0,
  },
  accrual: {
    points: 0,
    statusCredits: 0,
  },
};

/**
 *
 * @deprecated DO NOT USE
 */
export default function LuxLoyaltyManualEarn({ order, item, payments }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [pointsEarnResult, setPointsEarnResult] = useState(INITIAL_POINTS_EARN_RESULT);
  const [loading, setLoading] = useState(false);

  const earnPointsManually = (op: AccrualOp) => {
    setLoading(true);
    manualEarnLuxLoyaltyPoints(order, item, payments, op)
      .then((res) => {
        setPointsEarnResult(res.points);
        enqueueSnackbar('Points earned successfully');
      })
      .catch((err) => {
        enqueueSnackbar(`Error earning points: ${err.message}`, {
          variant: 'error',
        });
        setPointsEarnResult(INITIAL_POINTS_EARN_RESULT);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Stack direction="column" padding={2}>
      <Stack direction="row" padding={2}>
        <EarnButton onClick={() => earnPointsManually('force-earn')} disabled={loading}>
          {loading ? 'Earning Points...' : 'Force Earn'}
        </EarnButton>
        <EarnButton onClick={() => earnPointsManually('estimate')} disabled={loading}>
          {loading ? 'Earning Points...' : 'Estimate Earn'}
        </EarnButton>
      </Stack>
      {pointsEarnResult !== INITIAL_POINTS_EARN_RESULT && (
        <LuxLoyaltyManualEarnTable pointsBreakdown={pointsEarnResult} />
      )}
    </Stack>
  );
}
