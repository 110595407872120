import React from 'react';

import { useSnackbar } from 'notistack';

import { ContentCopy } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';

interface MetaInfoItemProps {
  label: string;
  value: string;
}

function MetaInfoItem({ label, value }: MetaInfoItemProps) {
  const { enqueueSnackbar } = useSnackbar();

  const handleCopyClick = (id: string) => {
    navigator.clipboard.writeText(id);
    enqueueSnackbar(`Copied to clipboard`, { variant: 'success' });
  };

  return (
    <Typography>
      <Typography component="span" fontWeight="bold">
        {label}:
      </Typography>
      &nbsp;{value}
      <IconButton title={`Copy ${label}`} onClick={() => handleCopyClick(value)}>
        <ContentCopy fontSize="small" />
      </IconButton>
    </Typography>
  );
}

export default MetaInfoItem;
