import React from 'react';

import { Box } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { formatDateOrdinalShortMonth } from '~/services/TimeService';

interface LuxLoyaltyAccrualsDetailsProps {
  accruals: Array<App.LuxLoyaltyAccrual>;
}

const accrualsColumns: Array<GridColDef> = [
  {
    field: 'points',
    headerName: 'Points',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueFormatter: (val: number) => val.toLocaleString(),
  },
  {
    field: 'statusCredits',
    headerName: 'Status Credits',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueFormatter: (val: number) => val.toLocaleString(),
  },
  {
    field: 'dateEarned',
    headerName: 'Accrual Date',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueGetter: (value?: string) => (value ? formatDateOrdinalShortMonth(new Date(value)) : 'Processing'),
  },
];

export default function LuxLoyaltyItemAccrualsDetails({ accruals }: LuxLoyaltyAccrualsDetailsProps) {
  if (!accruals || accruals.length === 0) {
    return null;
  }

  return (
    <Box>
      <DataGrid
        rows={accruals}
        columns={accrualsColumns}
        getRowId={(row) => row.id || row.itemId}
        autoHeight
        pageSizeOptions={[]}
        disableColumnMenu
        disableRowSelectionOnClick
        hideFooter
        getRowHeight={() => 'auto'}
      />
    </Box>
  );
}
