import React, {
  ChangeEventHandler,
  ComponentProps,
  FormEventHandler,
  MouseEventHandler,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import requestPostHeroPlannerTestEmail from '~/queries/customerCommunication/requestPostHeroPlannerTestEmail';

import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  createFilterOptions,
} from '@mui/material';

import { isRequestFulfilled, isRequestPending, isRequestRejected } from '~/utils/requestUtils';

import { SCHEDULE_BASE_INPUT_NAMES } from './ScheduleBaseForm';

enum TEST_SCHEDULE_EMAIL_INPUT_NAMES {
  SUBJECT_LINE = 'test_schedule_email_subject_line',
  RECIPIENTS = 'test_schedule_email_recipients',
}
const NOTIFICATION_PLAN_TEST_EMAIL_RECIPIENTS_STORAGE_KEY = 'notification_plan_test_email_recipients_storage_key';
const autocompleteFilter = createFilterOptions<string>();

interface Props {
  scheduleId: string;
  open: ComponentProps<typeof Dialog>['open'];
  onClose: ComponentProps<typeof Dialog>['onClose'];
  baseFormRef: MutableRefObject<HTMLFormElement>;
  subjectLine: string;
  scheduleType?: string;
}

function ScheduleTestEmailModal(props: Props) {
  const { scheduleId, baseFormRef, subjectLine: initialSubjectLine, scheduleType, open, onClose } = props;
  const formRef = useRef<HTMLFormElement>(null);
  const [subjectLine, setSubjectLine] = useState<string>('');
  const [recipients, setRecipients] = useState<Array<string>>([]);
  const serialisedRecipients = useMemo(() => recipients.join(','), [recipients]);
  const [recipientOptions, setRecipientOptions] = useState<Array<string>>([]);
  const persistRecipientOption = useCallback((newOptions: Array<string>) => {
    setRecipientOptions((curr) => {
      const next = Array.from(new Set([...newOptions, ...curr]));

      next.sort();
      localStorage.setItem(NOTIFICATION_PLAN_TEST_EMAIL_RECIPIENTS_STORAGE_KEY, next.join(','));

      return next;
    });
  }, []);
  const handleSubjectLineChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setSubjectLine(event.currentTarget.value);
  }, []);
  const handleRecipientsChange = useCallback<ComponentProps<typeof Autocomplete>['onChange']>(
    (event, value: Array<string>) => {
      const newValue = value.map((v) => v.replace('ADD ', ''));
      setRecipients(newValue);
      persistRecipientOption(newValue);
    },
    [persistRecipientOption],
  );
  const handleRecipientsFilter = useCallback<ComponentProps<typeof Autocomplete>['filterOptions']>(
    (options: Array<string>, params) => {
      const filtered = autocompleteFilter(options, params);
      const { inputValue } = params;

      const isValidEmail = !!inputValue && /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/.test(inputValue);
      const isNew = isValidEmail && options.every((option) => inputValue !== option);
      if (isNew) filtered.push(`ADD ${inputValue}`);

      return filtered;
    },
    [],
  );

  const forgetRecipients = useCallback(() => {
    setRecipientOptions([]);
    localStorage.removeItem(NOTIFICATION_PLAN_TEST_EMAIL_RECIPIENTS_STORAGE_KEY);
  }, []);

  const [testEmailReq, setTestEmailReq] = useState<
    Utils.RequestState<undefined, [scheduleId: string, subjectLine: string, recipientEmails: Array<string>]>
  >({ status: 'initial' });

  const handleSubmission = useCallback<FormEventHandler<HTMLFormElement>>(
    async (event) => {
      event.preventDefault();
      const baseFormData = new FormData(baseFormRef.current);
      const brand = String(baseFormData.get(SCHEDULE_BASE_INPUT_NAMES.BRAND_ID));
      const formData = new FormData(event.currentTarget);
      const subjectLine = String(formData.get(TEST_SCHEDULE_EMAIL_INPUT_NAMES.SUBJECT_LINE));
      const recipientEmailsString = String(formData.get(TEST_SCHEDULE_EMAIL_INPUT_NAMES.RECIPIENTS));
      const recipientEmails = recipientEmailsString ? recipientEmailsString.split(',') : [];
      const params = [brand, scheduleId, subjectLine, recipientEmails, scheduleType] as const;
      setTestEmailReq({ status: 'pending', params });
      try {
        await requestPostHeroPlannerTestEmail(...params);
        setTestEmailReq({ status: 'fulfilled', params, result: undefined });
      } catch (error) {
        setTestEmailReq({ status: 'rejected', params, error });
      }
    },
    [baseFormRef, scheduleId],
  );

  useEffect(() => {
    if (open) {
      setTestEmailReq({ status: 'initial' });
      setSubjectLine(initialSubjectLine || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, initialSubjectLine]);

  useEffect(() => {
    setRecipientOptions(localStorage.getItem(NOTIFICATION_PLAN_TEST_EMAIL_RECIPIENTS_STORAGE_KEY)?.split(',') ?? []);
  }, []);

  const handleDismissClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      onClose(event, 'escapeKeyDown');
    },
    [onClose],
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{
        ref: formRef,
        component: 'form',
        onSubmit: handleSubmission,
      }}
    >
      <DialogTitle>Send Test Email</DialogTitle>
      <DialogContent sx={{ position: 'relative' }}>
        <Stack direction="column" gap={2}>
          <TextField
            name={TEST_SCHEDULE_EMAIL_INPUT_NAMES.SUBJECT_LINE}
            label="Test Subject line"
            variant="standard"
            multiline
            required
            helperText="Changing this won't affect the existing subject field."
            value={subjectLine}
            disabled={isRequestPending(testEmailReq)}
            onChange={handleSubjectLineChange}
          />

          <Stack direction="column" gap={0.5}>
            <Autocomplete
              value={recipients}
              multiple
              options={recipientOptions}
              disabled={isRequestPending(testEmailReq)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Recipients"
                  error={!recipients.length}
                  helperText={!recipients.length ? 'Pick at least one recipient' : undefined}
                />
              )}
              filterOptions={handleRecipientsFilter}
              noOptionsText="Recipients will be persisted in your browser as you add them"
              onChange={handleRecipientsChange}
            />
            <input
              type="hidden"
              name={TEST_SCHEDULE_EMAIL_INPUT_NAMES.RECIPIENTS}
              required
              value={serialisedRecipients}
            />
            <Stack direction="row-reverse">
              <Button
                variant="text"
                size="small"
                color="warning"
                disabled={!recipientOptions.length}
                onClick={forgetRecipients}
              >
                Forget recipients
              </Button>
            </Stack>
          </Stack>
          {isRequestRejected(testEmailReq) && <Alert severity="error">{testEmailReq.error}</Alert>}
          {isRequestFulfilled(testEmailReq) && (
            <Alert severity="success">Request for test email send successful.</Alert>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="secondary" disabled={isRequestPending(testEmailReq)} onClick={handleDismissClick}>
          Dismiss
        </Button>
        <LoadingButton variant="contained" type="submit" loading={isRequestPending(testEmailReq)}>
          Send test email
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default ScheduleTestEmailModal;
