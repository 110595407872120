import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { CustomOfferFormValues } from '@/typings/formValues';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { v4 as uuid_v4 } from 'uuid';
import { customOfferSchema, editableCustomOfferSchema } from '~/validation/schemas/customOffers';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import HelpIcon from '@mui/icons-material/Help';
import RemoveIcon from '@mui/icons-material/Remove';
import SaveIcon from '@mui/icons-material/Save';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { getCurrencyCodes, getRegionByCurrency } from '@luxuryescapes/lib-regions';

import DateWidget from '~/components/Common/Elements/DateWidget';
import OrderDetailNotes from '~/components/Purchases/OrderDetail/OrderDetailNotes';

import { LE_BUSINESS_TRAVELLER } from '~/consts/businessTraveller';
import { OPERATIONS_SUPPORT } from '~/consts/notes';
import { OPERATION_REBOOK_AS_CUSTOM_OFFER } from '~/consts/order';
import { UTM_MEDIUM } from '~/consts/utm';

import { useAppSelector } from '~/hooks/store';
import useToggleState from '~/hooks/useToggleState';

import { getEmployee } from '~/services/BusinessTraveller/BusinessTravellerService';
import OffersService from '~/services/OffersService';
import * as OrdersService from '~/services/OrdersService';
import { addDays, formatDateISO, formatDateWithClock, isBefore, newDate } from '~/services/TimeService';

import { CurrencyDict, fetchSpotAndContractedRates } from '~/utils/customOfferFormUtils';

import ImagesForm from '../Forms/ImagesForm';
import { RichTextEditor } from '../Forms/fields';

import AccountsPayable from './CustomOfferForm/AccountsPayable';
import { CancellationPolicies } from './CustomOfferForm/CancellationPolicies';
import { PrePopulateCustomOfferForm } from './CustomOfferForm/PrePopulateCustomOfferForm';
import RatesTable from './CustomOfferForm/RatesTable';
import { HtmlTooltip } from './HTMLTooltip';
import {
  COUNTRIES_LIST,
  DEFAULT_ITEM_TYPE,
  FLEXIBLE_CANCELLATION_POLICY,
  LEBT_DEFAULT_ITEM_TYPE,
  LEBT_OFFER_TYPES,
  LE_ENTITY_AUS,
  LE_ENTITY_INTL,
  NONREFUNDABLE_CANCELLATION_POLICY,
  OFFER_TYPES,
  REASONS,
  SALES_ATTRIBUTION_LIST,
  SALES_ATTRIBUTION_MARGIN_MAX_VALUE,
} from './constants';
import { ConfirmDeleteDialog } from './dialogs/ConfirmDeleteDialog';
import { isFreeTextReason } from './utils';

type Props = {
  user: App.User;
  currentOffer?: App.CustomOffer;
  hasOrders?: boolean;
  order?: App.Order;
  onUpdate?: (offer: App.CustomOffer) => Promise<void>;
  paymentDate?: string;
};

const buildDefaultValues = (brand: string, offer?: App.CustomOffer): CustomOfferFormValues => {
  const today = new Date().toISOString().split('T')[0];
  // currently we support only one item per offer
  const item = offer?.items[0];
  const travellers = offer?.items[0]?.travellers;

  let reasonDefaultValue, reasonOtherDefaultValue;
  if (offer?.reason && isFreeTextReason(offer.reason)) {
    reasonDefaultValue = 'other';
    reasonOtherDefaultValue = offer.reason;
  } else {
    reasonDefaultValue = offer?.reason;
  }

  const defaultPaymentType = brand === LE_BUSINESS_TRAVELLER ? 'full_payment' : 'deposit';
  const defaultItemType = brand === LE_BUSINESS_TRAVELLER ? LEBT_DEFAULT_ITEM_TYPE : DEFAULT_ITEM_TYPE;

  const defaultValues: CustomOfferFormValues = {
    item: {
      type: item?.type ?? defaultItemType,
      name: item?.name ?? '',
      description: item?.description ?? '',
      vendor: item?.vendor ?? '',
      vendor_reference_number: item?.vendor_reference_number,
      salesforce_case_number: item?.salesforce_case_number,
      service_information: item?.service_information ?? '',
      start_date: item?.start_date ?? today,
      end_date: item?.end_date ?? formatDateISO(addDays(1)),
    },
    travellers: travellers?.length > 0 ? travellers : [{ name: '', date_of_birth: today, type: 'adult', country: '' }],
    price: offer?.price ? offer.price / 100 : 0,
    price_currency: offer?.price_currency ?? 'AUD',
    cost_price: offer?.cost_price ? offer.cost_price / 100 : 0,
    cost_price_currency: offer?.cost_price_currency ?? 'AUD',
    due_date: offer?.due_date ?? formatDateISO(addDays(1)),
    sold_at: offer?.sold_at ?? today,
    payment_terms: offer?.payment_terms ?? 'vcc',
    images: offer?.images ?? [],
    payment_metadata: {
      margin: offer?.payment_metadata?.margin / 100 ? offer.payment_metadata?.margin / 100 : 0,
      le_service_fee: offer?.payment_metadata?.le_service_fee ? offer.payment_metadata?.le_service_fee / 100 : 0,

      // customer Fields
      payment_type: offer?.payment_metadata?.payment_type ?? defaultPaymentType,
      customer_exchange_rate: offer?.payment_metadata?.customer_exchange_rate ?? 1,
      customer_paid: offer?.payment_metadata?.customer_paid ?? false,
      customer_deposit_1_amount:
        offer?.payment_metadata?.payment_type === 'deposit'
          ? (offer?.payment_metadata?.customer_deposit_1_amount ?? 0) / 100
          : 0,
      customer_deposit_1_due_date:
        offer?.payment_metadata?.payment_type === 'deposit'
          ? offer?.payment_metadata?.customer_deposit_1_due_date ?? today
          : undefined,
      customer_deposit_payment_made:
        offer?.payment_metadata?.payment_type === 'deposit'
          ? offer?.payment_metadata?.customer_deposit_payment_made ?? false
          : undefined,
      deposit_pi_number_customer:
        offer?.payment_metadata?.payment_type === 'deposit'
          ? offer?.payment_metadata?.deposit_pi_number_customer ?? ''
          : undefined,
      final_amount_customer: offer?.payment_metadata?.final_amount_customer
        ? offer.payment_metadata?.final_amount_customer / 100
        : 0,
      block_customer_payment_notifications: offer?.payment_metadata?.block_customer_payment_notifications ?? false,

      // vendor fields
      vendor_payment_type: offer?.payment_metadata?.vendor_payment_type ?? 'deposit',
      vendor_exchange_rate: offer?.payment_metadata?.vendor_exchange_rate ?? 1,
      vendor_due_date: offer?.payment_metadata?.vendor_due_date ?? formatDateISO(addDays(1)),
      vendor_was_paid: offer?.payment_metadata?.vendor_was_paid ?? false,
      vendor_deposit_1_amount:
        offer?.payment_metadata?.vendor_payment_type === 'deposit'
          ? (offer?.payment_metadata?.vendor_deposit_1_amount ?? 0) / 100
          : 0,
      vendor_deposit_1_due_date:
        offer?.payment_metadata?.vendor_payment_type === 'deposit'
          ? offer?.payment_metadata?.vendor_deposit_1_due_date ?? today
          : undefined,
      vendor_deposit_payment_made:
        offer?.payment_metadata?.vendor_payment_type === 'deposit'
          ? offer?.payment_metadata?.vendor_deposit_payment_made ?? false
          : undefined,
      final_payment_vcc_digits:
        offer?.payment_terms === 'vcc' ? offer?.payment_metadata?.final_payment_vcc_digits : undefined,
      deposit_vcc_digits:
        offer?.payment_terms === 'vcc' ?? offer?.payment_metadata?.payment_type === 'deposit'
          ? offer?.payment_metadata?.deposit_vcc_digits
          : undefined,
      final_amount_vendor: offer?.payment_metadata?.final_amount_vendor
        ? offer.payment_metadata?.final_amount_vendor / 100
        : 0,
      // set it to today to allow editing of pre-field offers
      vendor_payment_date:
        offer?.payment_metadata?.vendor_was_paid && offer?.payment_metadata?.vendor_payment_date
          ? offer?.payment_metadata.vendor_payment_date
          : today,
      vendor_deposit_payment_date:
        offer?.payment_metadata?.vendor_deposit_payment_made && offer?.payment_metadata?.vendor_deposit_payment_date
          ? offer?.payment_metadata.vendor_deposit_payment_date
          : today,
      refund_received: offer?.payment_metadata?.refund_received ?? null,
      le_entity: offer?.payment_metadata?.le_entity ?? LE_ENTITY_AUS,
      commission_received: offer?.payment_metadata?.commission_received ?? false,
      commission_amount: offer?.payment_metadata?.commission_amount
        ? offer?.payment_metadata?.commission_amount / 100
        : 0,
      additional_vendor_payment: offer?.payment_metadata?.additional_vendor_payment
        ? offer.payment_metadata.additional_vendor_payment / 100
        : 0,
      created_by: offer?.payment_metadata?.created_by ?? '',
      sales_agent_name: offer?.payment_metadata?.sales_agent_name ?? '',
      is_rebooking: offer?.payment_metadata?.is_rebooking ?? false,
      rebooking_from_order_id: offer?.payment_metadata?.rebooking_from_order_id ?? '',
      cancellation_end_date: offer?.payment_metadata?.cancellation_end_date ?? null,
      cancellation_type: offer?.payment_metadata?.cancellation_type ?? NONREFUNDABLE_CANCELLATION_POLICY,
      sales_attribution: offer?.payment_metadata?.sales_attribution ?? UTM_MEDIUM,
      additional_margin:
        offer?.payment_metadata?.additional_margin != null ? offer.payment_metadata.additional_margin / 100 : null,
      reason_additional_info: offer?.payment_metadata?.reason_additional_info,
      reason_issue_raised: offer?.payment_metadata?.reason_issue_raised,
    },
    notes: offer?.notes ?? '',
    created_from_booking_number: offer?.created_from_booking_number ?? null,
    created_from_item_id: offer?.created_from_item_id ?? null,
    reason: reasonDefaultValue,
    reasonOther: reasonOtherDefaultValue,
  };

  if (defaultValues.item.type == 'cruise') {
    defaultValues.item.cruise_line = item?.cruise_line ?? '';
    defaultValues.item.ship_name = item?.ship_name ?? '';
    defaultValues.item.ship_cabin = item?.ship_cabin ?? '';
    defaultValues.item.ship_rate = item?.ship_rate ?? '';
  }

  return defaultValues;
};

type Payload = Omit<App.CustomOffer, 'id' | 'status' | 'created_at' | 'updated_at'>;

const buildPayload = (user: App.User, formData: CustomOfferFormValues, images): Payload => {
  const { item, travellers, payment_metadata, reason, reasonOther, ...rest } = formData;

  const payload: Payload = {
    ...rest,
    price: Math.round(rest.price * 100),
    cost_price: Math.round(rest.cost_price * 100),
    // due_date will the instance of JS Date due to Yup validation and schema conversion
    due_date: formatDateISO(rest.due_date),
    sold_at: formatDateISO(rest.sold_at),
    payment_metadata: {
      ...payment_metadata,
      vendor_due_date: formatDateISO(payment_metadata.vendor_due_date),
      vendor_deposit_1_amount: Math.round(payment_metadata.vendor_deposit_1_amount * 100),
      vendor_deposit_1_due_date:
        payment_metadata?.vendor_payment_type === 'deposit'
          ? formatDateISO(payment_metadata.vendor_deposit_1_due_date)
          : null,
      customer_deposit_1_due_date:
        payment_metadata?.payment_type === 'deposit'
          ? formatDateISO(payment_metadata.customer_deposit_1_due_date)
          : null,
      customer_deposit_1_amount: Math.round(payment_metadata.customer_deposit_1_amount * 100),
      final_amount_customer: Math.round(payment_metadata.final_amount_customer * 100),
      final_amount_vendor: Math.round(payment_metadata.final_amount_vendor * 100),
      le_service_fee: Math.round(payment_metadata.le_service_fee * 100),
      margin: Math.round(payment_metadata.margin * 100),
      // ensure payments have been made before sending payment dates
      vendor_payment_date:
        payment_metadata?.vendor_payment_date && payment_metadata?.vendor_was_paid
          ? formatDateISO(payment_metadata.vendor_payment_date)
          : null,
      vendor_deposit_payment_date:
        payment_metadata?.vendor_deposit_payment_date && payment_metadata?.vendor_deposit_payment_made
          ? formatDateISO(payment_metadata.vendor_deposit_payment_date)
          : null,
      refund_received: payment_metadata?.refund_received ? formatDateISO(payment_metadata?.refund_received) : undefined,
      le_entity: payment_metadata?.le_entity,
      commission_received: payment_metadata?.commission_received,
      commission_amount: payment_metadata.commission_amount ? Math.round(payment_metadata.commission_amount * 100) : 0,
      additional_vendor_payment: payment_metadata.additional_vendor_payment
        ? Math.round(payment_metadata.additional_vendor_payment * 100)
        : 0,
      cancellation_type: payment_metadata?.cancellation_type,
      cancellation_end_date:
        payment_metadata?.cancellation_type === FLEXIBLE_CANCELLATION_POLICY
          ? formatDateISO(payment_metadata?.cancellation_end_date)
          : undefined,
      additional_margin:
        payment_metadata.additional_margin != null ? Math.round(payment_metadata.additional_margin * 100) : null,
      reason_additional_info: payment_metadata?.reason_additional_info,
      reason_issue_raised: payment_metadata?.reason_issue_raised,
    },
    name: item.name,
    fk_customer_id: user.id_member,
    customer_full_name: user.fullName,
    images: images,
    items: [
      {
        ...item,
        // start_date and end_date will the instance of JS Date due to Yup validation and schema conversion
        start_date: formatDateISO(item.start_date),
        end_date: formatDateISO(item.end_date),
        cruise_line: item?.type === DEFAULT_ITEM_TYPE ? item?.cruise_line : '',
        ship_name: item?.type === DEFAULT_ITEM_TYPE ? item?.ship_name : '',
        ship_cabin: item?.type === DEFAULT_ITEM_TYPE ? item?.ship_cabin : '',
        ship_rate: item?.type === DEFAULT_ITEM_TYPE ? item?.ship_rate : '',
        travellers: travellers.map((traveller) => ({
          ...traveller,
          name: traveller.name?.trim() === '' ? null : traveller.name,
          date_of_birth: traveller.date_of_birth ? formatDateISO(traveller.date_of_birth) : null,
        })),
      },
    ],
  };

  // both fields must be valid to send, otherwise remove defaults
  if (!(rest.created_from_booking_number && rest.created_from_item_id)) {
    delete payload['created_from_booking_number'];
    delete payload['created_from_item_id'];
  }

  // if reason is 'other', replace the value with the free text field value
  // held in reasonOther
  if (reason && reason === 'other' && reasonOther) {
    payload.reason = reasonOther;
  } else {
    payload.reason = reason ?? undefined;
  }

  return payload;
};

const buildPaymentPayload = async (user: App.User, offer: App.CustomOffer, brand: App.Brands = 'luxuryescapes') => {
  const transactionKey = uuid_v4();
  const total = offer.payment_metadata.final_amount_customer / 100;
  const vendorTotal = offer.payment_metadata.final_amount_vendor / 100;
  const regionCode = getRegionByCurrency(offer.price_currency, brand);

  let employee = undefined;
  if (brand === LE_BUSINESS_TRAVELLER) {
    employee = await getEmployee(user.id_member);
  }

  if (total <= 0 || vendorTotal <= 0) {
    throw new Error('Customer and Vendor total amount must be greater than 0');
  }

  return {
    brand,
    business_id: (employee && employee.businessId) ?? undefined,
    currency_code: offer.price_currency,
    region_code: regionCode.code,
    customer_id: user.id_member,
    customer_email: user.email,
    utm_source: brand,
    utm_medium: offer?.payment_metadata?.sales_attribution ?? UTM_MEDIUM,
    transaction_key: transactionKey,
    type: 'custom_offer',
    items: offer.items.map((item) => ({
      ...item,
      transaction_key: transactionKey,
      type: 'custom_offer',
      custom_offer_id: offer.id,
      due_date: offer.due_date,
      total,
      custom_item_type: item.type,
    })),
  };
};

export default function CustomOfferForm({ user, hasOrders, order, onUpdate, paymentDate, currentOffer }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [offer, setOffer] = useState<App.CustomOffer | undefined>(currentOffer);
  const [contractFxRates, setContractFxRates] = useState<CurrencyDict | undefined>(undefined);
  const [customerFxRates, setCustomerFxRates] = useState<CurrencyDict | undefined>(undefined);
  const [inclusionsDiscussed, setInclusionsDiscussed] = useState(offer?.status == 'approved' ?? false);
  const [termsExplained, setTermsExplained] = useState(offer?.status == 'approved' ?? false);
  const [sendStatus, setSendStatus] = useState<Utils.ProcessingState>('idle');
  const [images, setImages] = useState([]);
  const [formattedImages, setFormattedImages] = useState(offer?.images ?? []);

  const { isToggled: deleteDialogOpen, toggleOn: openDeleteDialog, toggleOff: closeDeleteDialog } = useToggleState();
  const brand = useAppSelector((state) => state.tenant.brand);
  const offerTypes = brand === LE_BUSINESS_TRAVELLER ? LEBT_OFFER_TYPES : OFFER_TYPES;

  const currencies = useMemo(() => getCurrencyCodes(brand).sort((a, b) => a.localeCompare(b)), []);

  useEffect(() => {
    async function fetchFxRates() {
      const { customerFxRate, contractFxRate } = await fetchSpotAndContractedRates(currencies, enqueueSnackbar);
      setCustomerFxRates(customerFxRate);
      setContractFxRates(contractFxRate);
    }
    fetchFxRates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setImages(
      offer?.images
        ? offer?.images.map((id) => ({
            id: id,
            publicImageId: id,
          }))
        : [],
    );
  }, [offer]);

  const history = useHistory();

  const isEditMode = !!offer?.id;

  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
    watch,
    clearErrors,
    setValue,
  } = useForm<CustomOfferFormValues>({
    defaultValues: buildDefaultValues(brand, offer),
    context: { editMode: isEditMode },
    resolver: yupResolver(hasOrders ? editableCustomOfferSchema : customOfferSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const {
    fields: travellers,
    append: addTraveller,
    remove: removeTraveller,
  } = useFieldArray({ control, name: 'travellers' });

  const costPrice = watch('cost_price');
  const costPriceCurrencyCode = watch('cost_price_currency');
  const createdFromBookingNumber = watch('created_from_booking_number');
  const createdFromItemId = watch('created_from_item_id');
  const customerDepositAmount = watch('payment_metadata.customer_deposit_1_amount');
  const customerExchangeRate = watch('payment_metadata.customer_exchange_rate');
  const isRebooking = watch('payment_metadata.is_rebooking');
  const itemType = watch('item.type');
  const leServiceFee = watch('payment_metadata.le_service_fee');
  const margin = watch('payment_metadata.margin');
  const paymentTermsGlobal = watch('payment_terms');
  const paymentType = watch('payment_metadata.payment_type');
  const price = watch('price');
  const priceCurrencyCode = watch('price_currency');
  const rebookingFromOrderId = watch('payment_metadata.rebooking_from_order_id');
  const vendorDepositAmount = watch('payment_metadata.vendor_deposit_1_amount');
  const vendorDepositPaymentMade = watch('payment_metadata.vendor_deposit_payment_made');
  const vendorExchangeRate = watch('payment_metadata.vendor_exchange_rate');
  const vendorFinalAmout = watch('payment_metadata.final_amount_vendor');
  const vendorPaymentMade = watch('payment_metadata.vendor_was_paid');
  const vendorPaymentType = watch('payment_metadata.vendor_payment_type');
  const salesAttribution = watch('payment_metadata.sales_attribution');
  const reason = watch('reason');

  const isItemTypeCruise = itemType == 'cruise';
  const isActionsDisabled = isSubmitting || sendStatus == 'processing';
  const isSendDisabled = !inclusionsDiscussed || !termsExplained || isActionsDisabled;
  const isAdditionalMarginVisible = salesAttribution === SALES_ATTRIBUTION_MARGIN_MAX_VALUE;

  const showReasonInput = ['hotel', 'cruise', 'tour', 'experience'].includes(itemType);
  const showReasonOtherInput = reason === 'other';

  const showSendButton = !!offer?.id && !hasOrders;

  const onSubmit: SubmitHandler<CustomOfferFormValues> = async (formData) => {
    try {
      // so far we support only one item
      const payload = buildPayload(user, formData, formattedImages);

      const response = offer?.id
        ? await OffersService.updateCustomOffer(offer.id, payload)
        : await OffersService.createCustomOffer(payload);

      const updatedOffer = response.result as App.CustomOffer;

      setOffer(updatedOffer);

      if (onUpdate) {
        await onUpdate(updatedOffer);
      }

      enqueueSnackbar(
        offer?.id ? 'Custom quote has been successfully updated' : 'Custom quote has been successfully created',
        { variant: 'success' },
      );

      if (isRebooking && offer && offer?.payment_metadata?.rebooking_from_order_id !== rebookingFromOrderId) {
        // find the order associated with the custom offer
        const customOrder = await OrdersService.getCustomOrderByCustomOffer(offer.id);
        const relatedOrderId = createdFromBookingNumber ? customOrder.id_orders : null;

        await OrdersService.updateOrderItem(rebookingFromOrderId, createdFromItemId, {
          op: OPERATION_REBOOK_AS_CUSTOM_OFFER,
          fk_rebooked_custom_order_id: relatedOrderId,
        });
        enqueueSnackbar(`Linked LPP order has been updated`, { variant: 'success' });
      }

      history.replace(`/users/${user.id_member}/custom-offers/${updatedOffer.id}`);
    } catch (err) {
      enqueueSnackbar(`Can't process custom quote: ${err.message}`, { variant: 'error' });
    }
  };

  const onSendPayment = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!offer?.id) {
      return;
    }

    try {
      setSendStatus('processing');

      const dueDate = offer.due_date;
      if (isBefore(dueDate)) {
        throw new Error('Due date must be in the future');
      }

      const offerResponse = await OffersService.setCustomOfferStatus(offer.id, 'approved');
      const updatedOffer = offerResponse.result as App.CustomOffer;

      setOffer(updatedOffer);

      const payload = await buildPaymentPayload(user, updatedOffer, brand);

      const response = await OrdersService.createOrder(payload);

      if (offer.notes && offer.notes.length > 0) {
        await OrdersService.addOrderNote(response.result.id_orders, {
          comment: offer.notes,
          comment_type: OPERATIONS_SUPPORT,
        });
      }

      enqueueSnackbar('Payment request has been successfully created', { variant: 'success' });

      if (isRebooking) {
        await OrdersService.updateOrderItem(rebookingFromOrderId, createdFromItemId, {
          op: OPERATION_REBOOK_AS_CUSTOM_OFFER,
          fk_rebooked_custom_order_id: response.result.id_orders,
        });
        enqueueSnackbar(`Linked LPP Order has been updated`, { variant: 'success' });
      }
      setSendStatus('success');

      setTimeout(() => window.location.reload(), 200);
    } catch (err) {
      setSendStatus('failed');
      enqueueSnackbar(`Can't create payment request: ${err.message}`, { variant: 'error' });
    }
  };

  const onUpdateImages = (newImageList) => {
    const mappedImages = newImageList.map((image) => image.id);
    return setFormattedImages([...mappedImages]);
  };

  const onAddImage = (cloudinaryId, filename) => {
    const newImage = { id: cloudinaryId, publicImageId: cloudinaryId, title: filename };
    setImages([newImage, ...images]);
    return Promise.resolve(newImage);
  };

  const onDeleteImage = async (imageId) => {
    // await deleteImage(imageId);
    setImages((images) => images.filter((image) => image.id !== imageId));
    return;
  };

  const deleteOffer = async () => {
    OffersService.deleteCustomOffer(offer.id).then(() => history.push(`/users/${user.id_member}`));
  };

  const handleCostPriceCurrencyChange = useCallback(
    // cost price == vendor
    (name: string, value: string) => {
      if (contractFxRates) {
        const fieldName = name as keyof CustomOfferFormValues;
        const costPriceCurrencyCode = value;

        const selectCostPriceFxRate = contractFxRates[costPriceCurrencyCode];

        if (!selectCostPriceFxRate || selectCostPriceFxRate === 0) {
          enqueueSnackbar(`Exchange rate not found for ${costPriceCurrencyCode}`, { variant: 'error' });
        }

        setValue('payment_metadata.vendor_exchange_rate', selectCostPriceFxRate ?? 0);

        if (costPriceCurrencyCode === priceCurrencyCode) {
          // ensure same rate is used for equivalent currency codes
          setValue('payment_metadata.customer_exchange_rate', selectCostPriceFxRate ?? 0);
        }
        setValue(fieldName, costPriceCurrencyCode);
      }
    },
    [costPriceCurrencyCode, priceCurrencyCode, contractFxRates, setValue, enqueueSnackbar],
  );

  const handleAddNewTraveller = useCallback(
    (type = 'adult') => {
      addTraveller({
        name: '',
        type: type,
        country: COUNTRIES_LIST[0],
        date_of_birth: null,
      } as App.CustomOfferTraveller);
    },
    [addTraveller],
  );

  const handlePriceCurrencyChange = useCallback(
    // customer
    (name: string, value: string) => {
      if (customerFxRates) {
        const fieldName = name as keyof CustomOfferFormValues;
        const priceCurrencyCode = value;
        const selectPriceFxRate = customerFxRates[priceCurrencyCode];

        if (!selectPriceFxRate || selectPriceFxRate === 0) {
          enqueueSnackbar(`Exchange rate not found for ${priceCurrencyCode}`, { variant: 'error' });
        }

        if (costPriceCurrencyCode === priceCurrencyCode) {
          // hardcode this to the same rate as vendor
          setValue('payment_metadata.customer_exchange_rate', vendorExchangeRate);
        } else {
          setValue('payment_metadata.customer_exchange_rate', selectPriceFxRate ?? 0);
        }

        setValue(fieldName, priceCurrencyCode);

        if (priceCurrencyCode === 'AUD') {
          setValue('payment_metadata.le_entity', LE_ENTITY_AUS);
        } else {
          setValue('payment_metadata.le_entity', LE_ENTITY_INTL);
        }
      }
    },
    [costPriceCurrencyCode, priceCurrencyCode, vendorExchangeRate, customerFxRates, setValue, enqueueSnackbar],
  );

  const calculateVendorCostTotal = useCallback(() => {
    return Number(vendorDepositAmount) + Number(vendorFinalAmout);
  }, [vendorDepositAmount, vendorFinalAmout]);

  const calculateVendorCostTotalWithMargin = useCallback(() => {
    return Number(vendorDepositAmount) + Number(vendorFinalAmout) + Number(margin) + Number(leServiceFee);
  }, [leServiceFee, margin, vendorDepositAmount, vendorFinalAmout]);

  const calculateVendorTotalFromCostPrice = useCallback(() => {
    return Number(costPrice) - (Number(margin) + Number(leServiceFee));
  }, [costPrice, leServiceFee, margin]);

  const calculateVendorFinalPaymentAmount = useCallback(() => {
    return Number(costPrice) - Number(vendorDepositAmount);
  }, [costPrice, vendorDepositAmount]);

  // We need to Calculate the vendor cost total for the form.
  const calculateCustomerPriceTotalAndCurrencyConvert = useCallback(() => {
    const priceTotalInCostCurrency = Number(costPrice) + Number(margin) + Number(leServiceFee);
    const customerTotal = priceTotalInCostCurrency * (Number(customerExchangeRate) / Number(vendorExchangeRate));
    return Math.round((customerTotal + Number.EPSILON) * 100) / 100;
  }, [costPrice, customerExchangeRate, leServiceFee, margin, vendorExchangeRate]);

  const calculateCustomerFinalPaymentAmount = useCallback(() => {
    const finalPaymentAmount =
      paymentType === 'deposit'
        ? Math.round(Number(price) * 100 - Number(customerDepositAmount) * 100)
        : Math.round(Number(price) * 100);
    return finalPaymentAmount / 100;
  }, [customerDepositAmount, paymentType, price]);

  // Update the vendor cost price when the any of the following fields are changed.
  // vendor_deposit_1_amount | final_amount_vendor | margin | le_service_fee
  useEffect(() => {
    if (!hasOrders) {
      setValue('cost_price', calculateVendorCostTotal());
      clearErrors('cost_price');
    }
  }, [hasOrders, setValue, calculateVendorCostTotal, clearErrors]);

  // If the finance team need to amend the vendor payment terms after the order has been created.  I.e. swap to deposit.
  // Only update the vendor final amount without affecting any other values on the form.
  useEffect(() => {
    if (hasOrders) {
      setValue('payment_metadata.final_amount_vendor', calculateVendorFinalPaymentAmount());
      clearErrors('payment_metadata.final_amount_vendor');
    }
  }, [calculateVendorFinalPaymentAmount, clearErrors, hasOrders, setValue]);

  // Update the customer price when the vendor cost price is changed.
  useEffect(() => {
    if (!hasOrders) {
      setValue('price', calculateCustomerPriceTotalAndCurrencyConvert());
      clearErrors('price');
    }
  }, [setValue, hasOrders, calculateCustomerPriceTotalAndCurrencyConvert, clearErrors]);

  // update the customer final payment amount
  useEffect(() => {
    setValue('payment_metadata.final_amount_customer', calculateCustomerFinalPaymentAmount());
    clearErrors('payment_metadata.final_amount_customer');
  }, [calculateCustomerFinalPaymentAmount, clearErrors, setValue]);

  // update the vendor payment amounts if the payment type is changed.
  useEffect(() => {
    if (vendorPaymentType !== 'deposit' && vendorDepositAmount !== 0) {
      if (hasOrders) {
        setValue('payment_metadata.final_amount_vendor', calculateVendorTotalFromCostPrice());
        clearErrors('payment_metadata.final_amount_vendor');
      }
      setValue('payment_metadata.vendor_deposit_1_amount', 0);
      clearErrors('payment_metadata.vendor_deposit_1_amount');
    }
  }, [calculateVendorTotalFromCostPrice, clearErrors, hasOrders, setValue, vendorDepositAmount, vendorPaymentType]);

  // reset the customer deposit amount if the payment type is changed.
  useEffect(() => {
    if (paymentType !== 'deposit') {
      setValue('payment_metadata.customer_deposit_1_amount', 0);
      clearErrors('payment_metadata.customer_deposit_1_amount');
    }
  }, [clearErrors, paymentType, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={4}>
          <Typography variant="h6" fontWeight="normal" color="GrayText">
            Customer: {user.fullName}
          </Typography>
          <Typography variant="h6" fontWeight="normal" color="GrayText">
            Email: {user.email}
          </Typography>
        </Stack>
        {itemType === 'hotel' && (
          <Stack direction="row" spacing={2}>
            <PrePopulateCustomOfferForm
              sendStatus={sendStatus}
              setSendStatus={setSendStatus}
              setValue={setValue}
              watch={watch}
              user={user}
              control={control}
              isSubmitting={isSubmitting}
              handleAddNewTraveller={handleAddNewTraveller}
            />
          </Stack>
        )}
      </Stack>

      <Box>
        <Typography variant="h6">General offer information</Typography>
      </Box>

      <Grid mt={1} columns={6} spacing={2} container>
        <Grid xs={3} md={1}>
          <Controller
            name="item.type"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Offer type"
                helperText={error?.message}
                error={!!error}
                disabled={isSubmitting}
                select
                fullWidth
              >
                {offerTypes.map((offerType) => (
                  <MenuItem key={offerType.value} value={offerType.value}>
                    {offerType.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid xs={3} md={1}>
          <Controller
            name="item.vendor"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Supplier"
                helperText={error?.message}
                error={!!error}
                disabled={isSubmitting}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid xs={3} md={1}>
          <Controller
            name="item.salesforce_case_number"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Salesforce case number"
                helperText={error?.message}
                error={!!error}
                disabled={isSubmitting}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid xs={3} md={1}>
          <Controller
            name="item.vendor_reference_number"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Supplier booking reference"
                helperText={error?.message}
                error={!!error}
                disabled={isSubmitting}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid xs={3} md={1}>
          <Controller
            name="sold_at"
            control={control}
            render={({ field: { ref: _, ...field }, fieldState: { error } }) => (
              <DateWidget
                {...field}
                label="Sales date"
                slotProps={{ textField: { helperText: error?.message, error: !!error } }}
                disabled={isSubmitting || hasOrders}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid xs={3} md={1}>
          <Controller
            name="payment_metadata.sales_attribution"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Sales Attribution"
                helperText={error?.message}
                error={!!error}
                disabled={isSubmitting}
                select
                fullWidth
                InputLabelProps={{ shrink: !!field.value }}
              >
                {SALES_ATTRIBUTION_LIST.map((attributionType) => (
                  <MenuItem key={attributionType.value} value={attributionType.value}>
                    {attributionType.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
      </Grid>

      <Grid mt={1} columns={6} spacing={2} container>
        <Grid xs={isAdditionalMarginVisible ? 5 : 6}>
          <TextField
            {...register('item.name')}
            label="Offer name"
            helperText={
              errors.item?.name
                ? errors.item.name.message
                : 'This is a customer-facing field and will be visible on customer portal and emails'
            }
            InputLabelProps={{ shrink: !!watch('item.name') }}
            error={!!errors.item?.name}
            disabled={isSubmitting}
            fullWidth
          />
        </Grid>
        {isAdditionalMarginVisible && (
          <Grid xs={1}>
            <TextField
              {...register('payment_metadata.additional_margin')}
              label="Additional margin"
              error={!!errors.payment_metadata?.additional_margin}
              helperText={errors.payment_metadata?.additional_margin?.message}
              disabled={isSubmitting}
              type="number"
              inputProps={{ step: 0.01 }}
              fullWidth
            />
          </Grid>
        )}
      </Grid>

      <Grid mt={1} columns={4} spacing={2} container>
        <Grid xs={3} md={0.5}>
          <Controller
            name="item.start_date"
            control={control}
            render={({ field: { ref: _, ...field }, fieldState: { error } }) => (
              <DateWidget
                {...field}
                label="Start date"
                slotProps={{ textField: { helperText: error?.message, error: !!error } }}
                disabled={isSubmitting}
                fullWidth
                disablePast
              />
            )}
          />
        </Grid>
        <Grid xs={3} md={0.5}>
          <Controller
            name="item.end_date"
            control={control}
            render={({ field: { ref: _, ...field }, fieldState: { error } }) => (
              <DateWidget
                {...field}
                slotProps={{ textField: { helperText: error?.message, error: !!error } }}
                disabled={isSubmitting}
                label="End date"
                disablePast
                minDate={watch(`item.start_date`) ? newDate(watch(`item.start_date`)) : undefined}
              />
            )}
          />
        </Grid>
        {showReasonInput && (
          <Grid xs={3} md={1}>
            <Controller
              name="reason"
              control={control}
              render={({ field: { ref: _, ...field }, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Reason for custom offer"
                  helperText={error?.message}
                  error={!!error}
                  disabled={isSubmitting}
                  select
                  fullWidth
                  required
                >
                  {REASONS.map((reason, index) => (
                    <MenuItem value={reason.value} key={index}>
                      {reason.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
        )}
        {showReasonOtherInput && (
          <Grid xs={2}>
            <Controller
              name="reasonOther"
              control={control}
              render={({ field: { ref: _, ...field }, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Reason for custom offer - Other"
                  helperText={error?.message}
                  error={!!error}
                  disabled={isSubmitting}
                  required
                  fullWidth
                />
              )}
            />
          </Grid>
        )}
      </Grid>

      <Grid mt={1} columns={4} spacing={2} container>
        {isItemTypeCruise && (
          <>
            <Grid xs={4} md={1}>
              <TextField
                {...register('item.cruise_line')}
                helperText={errors.item?.cruise_line?.message}
                error={!!errors.item?.cruise_line}
                disabled={isSubmitting}
                label="Cruise Line"
                fullWidth
              />
            </Grid>

            <Grid xs={4} md={1}>
              <TextField
                {...register('item.ship_name')}
                helperText={errors.item?.ship_name?.message}
                error={!!errors.item?.ship_name}
                disabled={isSubmitting}
                label="Ship"
                fullWidth
              />
            </Grid>

            <Grid xs={4} md={1}>
              <TextField
                {...register('item.ship_cabin')}
                helperText={errors.item?.ship_cabin?.message}
                error={!!errors.item?.ship_cabin}
                disabled={isSubmitting}
                label="Cabin"
                fullWidth
              />
            </Grid>

            <Grid xs={4} md={1}>
              <TextField
                {...register('item.ship_rate')}
                helperText={errors.item?.ship_rate?.message}
                error={!!errors.item?.ship_rate}
                disabled={isSubmitting}
                label="Rate"
                fullWidth
              />
            </Grid>

            <Grid xs={4} md={2}>
              <TextField
                {...register('payment_metadata.reason_additional_info')}
                error={!!errors.payment_metadata?.reason_additional_info}
                disabled={isSubmitting}
                label="Additional information"
                helperText={
                  errors.payment_metadata?.reason_additional_info
                    ? errors.payment_metadata?.reason_additional_info.message
                    : 'Provide 1-2 additional sentences on why custom offer is required'
                }
                fullWidth
                required
              />
            </Grid>

            <Grid xs={4} md={2}>
              <Controller
                name="payment_metadata.reason_issue_raised"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label="Was this issue raised internally? (007/Connectivity)"
                  />
                )}
              />
            </Grid>
          </>
        )}

        <Grid xs={4}>
          <RichTextEditor
            {...register('item.description')}
            control={control}
            label={isItemTypeCruise ? 'Key information' : 'Description'}
            helperText={
              errors.item?.description
                ? errors.item.description.message
                : 'This is a customer-facing field and will be visible on customer portal and emails'
            }
            error={!!errors.item?.description}
            disabled={isSubmitting}
          />
        </Grid>
        <Grid xs={4}>
          <RichTextEditor
            {...register('item.service_information')}
            control={control}
            label="Terms & Conditions"
            helperText={
              errors.item?.service_information
                ? errors.item.service_information.message
                : 'This is a customer-facing field and will be visible on customer portal and emails'
            }
            error={!!errors.item?.service_information}
            disabled={isSubmitting}
          />
        </Grid>
        {itemType === 'hotel' && (
          <CancellationPolicies control={control} watch={watch} isSubmitting={isSubmitting} setValue={setValue} />
        )}
      </Grid>

      <Box mt={2}>
        <Typography variant="h6">Traveller details (per government issued ID)</Typography>
      </Box>

      {travellers.map((traveller, index) => (
        <Grid mt={1} container columns={6} spacing={2} key={traveller.id}>
          <Grid xs={6} md={2}>
            <TextField
              {...register(`travellers.${index}.name`)}
              helperText={errors.travellers?.[index]?.name?.message}
              error={!!errors.travellers?.[index]?.name}
              disabled={isSubmitting}
              required={index === 0}
              placeholder="Traveller name"
              fullWidth
            />
          </Grid>

          <Grid xs={6} md={1}>
            <Controller
              name={`travellers.${index}.country`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Country of residence"
                  helperText={error?.message}
                  error={!!error}
                  disabled={isSubmitting}
                  required={index === 0}
                  select
                  fullWidth
                >
                  {COUNTRIES_LIST.map((country) => (
                    <MenuItem value={country} key={country}>
                      {country}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>

          <Grid xs={6} md={1}>
            <Controller
              control={control}
              name={`travellers.${index}.date_of_birth`}
              render={({ field: { ref: _, ...field }, fieldState: { error } }) => (
                <DateWidget
                  {...field}
                  slotProps={{ textField: { helperText: error?.message, error: !!error } }}
                  disabled={isSubmitting}
                  label="Date of birth"
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid xs={6} md={1}>
            <Controller
              name={`travellers.${index}.type`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Traveller type"
                  helperText={error?.message}
                  error={!!error}
                  disabled={isSubmitting}
                  select
                  fullWidth
                >
                  <MenuItem value="adult">Adult</MenuItem>
                  <MenuItem value="child">Child</MenuItem>
                  <MenuItem value="infant">Infant</MenuItem>
                </TextField>
              )}
            />
          </Grid>

          <Grid xs={6} md={1}>
            {index == 0 && !isSubmitting && (
              <IconButton
                color="success"
                title="Add traveller"
                onClick={() => {
                  handleAddNewTraveller();
                }}
              >
                <AddIcon />
              </IconButton>
            )}
            {index != 0 && !isSubmitting && (
              <IconButton color="error" title="Remove traveller" onClick={() => removeTraveller(index)}>
                <RemoveIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      ))}

      <Box mt={2}>
        <Typography variant="h6">Vendor payment information</Typography>
      </Box>

      <Grid mt={2} container columns={6} spacing={2}>
        <Grid xs={3} md={1}>
          <Controller
            name="payment_metadata.vendor_payment_type"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Payment type"
                disabled={isSubmitting}
                select
                fullWidth
                helperText={errors.payment_metadata?.vendor_payment_type?.message}
                error={!!errors?.payment_metadata?.vendor_payment_type}
              >
                <MenuItem value="deposit">Deposit</MenuItem>
                <MenuItem value="full_payment">Full Payment</MenuItem>
              </TextField>
            )}
          />
        </Grid>
        <Grid xs={3} md={1}>
          <Controller
            name="payment_terms"
            control={control}
            render={({ field }) => (
              <TextField {...field} label="Payment terms" disabled={isSubmitting} select fullWidth>
                <MenuItem value="vcc">VCC</MenuItem>
                <MenuItem value="eft">EFT</MenuItem>
              </TextField>
            )}
          />
        </Grid>
        <Grid xs={3} md={1}>
          <Controller
            name="cost_price_currency"
            control={control}
            rules={{ required: 'Please select an vendor currency' }}
            render={({ field, fieldState }) => (
              <FormControl error={fieldState.invalid} fullWidth>
                <Autocomplete
                  {...field}
                  options={currencies}
                  autoHighlight
                  disableClearable
                  renderInput={(params) => <TextField {...params} label="Vendor Currency" />}
                  onChange={(_, data) => {
                    handleCostPriceCurrencyChange(field.name, data);
                  }}
                />
                <FormHelperText>{fieldState.error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid xs={3} md={1} container direction="row" alignItems="center">
          <Typography variant="subtitle2">
            AUD to {costPriceCurrencyCode} Fx Rate = {vendorExchangeRate}
          </Typography>
          <HtmlTooltip
            title={
              <RatesTable
                vendorCurrency={costPriceCurrencyCode}
                customerCurrency={priceCurrencyCode}
                customerFxRates={customerFxRates}
                contractFxRates={contractFxRates}
              />
            }
            placement="right"
          >
            <IconButton>
              <HelpIcon />
            </IconButton>
          </HtmlTooltip>
        </Grid>
      </Grid>

      {vendorPaymentType === 'deposit' && (
        <Grid mt={2} container columns={6} spacing={2}>
          <Grid xs={3} md={1}>
            <TextField
              {...register('payment_metadata.vendor_deposit_1_amount')}
              helperText={errors.payment_metadata?.vendor_deposit_1_amount?.message}
              error={!!errors.payment_metadata?.vendor_deposit_1_amount}
              disabled={isSubmitting}
              label="Deposit amount (vendor)"
              type="number"
              inputProps={{ step: 0.01 }}
              fullWidth
            />
          </Grid>
          <Grid xs={3} md={1}>
            <Controller
              name="payment_metadata.vendor_deposit_1_due_date"
              control={control}
              render={({ field: { ref: _, ...field }, fieldState: { error } }) => (
                <DateWidget
                  {...field}
                  slotProps={{ textField: { helperText: error?.message, error: !!error } }}
                  disabled={isSubmitting}
                  label="Deposit 1 due date (vendor)"
                />
              )}
            />
          </Grid>
          {paymentTermsGlobal == 'vcc' && (
            <Grid xs={3} md={1}>
              <Controller
                name="payment_metadata.deposit_vcc_digits"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    helperText={error?.message}
                    error={!!error}
                    disabled={isSubmitting}
                    label="Deposit VCC 4 digits field"
                    required={paymentType === 'deposit'}
                    fullWidth
                    type="text"
                    onChange={(event) => {
                      const { value } = event.target;
                      const onlyNums = value.replace(/[^0-9]/g, '');
                      if (onlyNums.length < 5) {
                        field.onChange(onlyNums);
                      }
                    }}
                  />
                )}
              />
            </Grid>
          )}
          <Grid xs={3} md={1}>
            <Controller
              name="payment_metadata.vendor_deposit_payment_made"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label="Deposit payment made"
                />
              )}
            />
          </Grid>
          {vendorDepositPaymentMade && (
            <Grid xs={3} md={1}>
              <Controller
                name="payment_metadata.vendor_deposit_payment_date"
                control={control}
                render={({ field: { ref: _, ...field }, fieldState: { error } }) => (
                  <DateWidget
                    {...field}
                    slotProps={{ textField: { helperText: error?.message, error: !!error } }}
                    disabled={isSubmitting}
                    label="Deposit payment date"
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      )}

      <Grid mt={2} container columns={6} spacing={2}>
        <Grid xs={3} md={1}>
          <TextField
            {...register('payment_metadata.final_amount_vendor')}
            helperText={errors?.payment_metadata?.final_amount_vendor?.message}
            error={!!errors?.payment_metadata?.final_amount_vendor}
            disabled={hasOrders || isSubmitting}
            label="Final amount (vendor)"
            type="number"
            inputProps={{ step: 0.01 }}
            fullWidth
          />
        </Grid>
        <Grid xs={3} md={1}>
          <Controller
            name="payment_metadata.vendor_due_date"
            control={control}
            render={({ field: { ref: _, ...field }, fieldState: { error } }) => (
              <DateWidget
                {...field}
                slotProps={{ textField: { helperText: error?.message, error: !!error } }}
                disabled={isSubmitting}
                label="Payment due date (vendor)"
                disablePast
              />
            )}
          />
        </Grid>
        {paymentTermsGlobal == 'vcc' && (
          <Grid xs={3} md={1}>
            <Controller
              name="payment_metadata.final_payment_vcc_digits"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  helperText={error?.message}
                  error={!!error}
                  disabled={isSubmitting}
                  label="Final payment VCC 4 digits"
                  fullWidth
                  type="text"
                  onChange={(event) => {
                    const { value } = event.target;
                    const onlyNums = value.replace(/[^0-9]/g, '');
                    if (onlyNums.length < 5) {
                      field.onChange(onlyNums);
                    }
                  }}
                />
              )}
            />
          </Grid>
        )}
        <Grid xs={3} md={1}>
          <Controller
            name="payment_metadata.vendor_was_paid"
            control={control}
            render={({ field }) => (
              <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label="Vendor payment made" />
            )}
          />
        </Grid>
        {vendorPaymentMade && (
          <Grid xs={3} md={1}>
            <Controller
              name="payment_metadata.vendor_payment_date"
              control={control}
              render={({ field: { ref: _, ...field }, fieldState: { error } }) => (
                <DateWidget
                  {...field}
                  slotProps={{ textField: { helperText: error?.message, error: !!error } }}
                  disabled={isSubmitting}
                  label="Vendor payment date"
                />
              )}
            />
          </Grid>
        )}
      </Grid>

      <Box mt={2}>
        <Typography variant="h6">Vendor Offer Prices - {costPriceCurrencyCode}</Typography>
      </Box>

      <Grid mt={2} container columns={6} spacing={2}>
        <Grid xs={3} md={1}>
          <TextField
            {...register('cost_price')}
            helperText={errors.cost_price?.message}
            error={!!errors.cost_price}
            disabled
            InputLabelProps={{ shrink: true }}
            label="Cost price"
            type="number"
            fullWidth
          />
        </Grid>
        <Grid xs={3} md={1}>
          <TextField
            {...register('payment_metadata.margin')}
            helperText={errors.payment_metadata?.margin?.message}
            error={!!errors.payment_metadata?.margin}
            label="Margin"
            disabled={hasOrders || isSubmitting}
            type="number"
            inputProps={{ step: 0.01 }}
            defaultValue={190}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Grid>
        <Grid xs={3} md={1}>
          <TextField
            {...register('payment_metadata.le_service_fee')}
            helperText={errors.payment_metadata?.le_service_fee?.message}
            error={!!errors.payment_metadata?.le_service_fee}
            disabled={hasOrders || isSubmitting}
            label="Le service fee"
            type="number"
            inputProps={{ step: 0.01 }}
            fullWidth
          />
        </Grid>
        <Grid xs={3} md={1} container direction="row" alignItems="center">
          <Typography variant="subtitle2">
            Sell Price ({costPriceCurrencyCode}): {calculateVendorCostTotalWithMargin()}
          </Typography>
        </Grid>
      </Grid>

      <Box mt={2}>
        <Typography variant="h6">Customer Offer Price - {priceCurrencyCode}</Typography>
      </Box>

      <Grid mt={2} container columns={6} spacing={2}>
        <Grid xs={3} md={1}>
          <TextField
            {...register('price')}
            helperText={errors.price?.message}
            error={!!errors.price}
            disabled
            label="Sell price"
            fullWidth
          />
        </Grid>
        <Grid xs={3} md={1}>
          <Controller
            name="price_currency"
            control={control}
            rules={{ required: 'Please select an customer currency' }}
            render={({ field, fieldState }) => (
              <FormControl error={fieldState.invalid} fullWidth>
                <Autocomplete
                  {...field}
                  options={currencies}
                  autoHighlight
                  disableClearable
                  renderInput={(params) => <TextField {...params} label="Customer Currency" />}
                  onChange={(_, data) => {
                    handlePriceCurrencyChange(field.name, data);
                  }}
                />
                <FormHelperText>{fieldState.error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid xs={3} md={1} container direction="row" alignItems="center">
          <HtmlTooltip
            title={
              <Stack direction="column" gap={1}>
                <Typography variant="subtitle2">Currency conversion</Typography>
                <Typography variant="body2">
                  = (Vendor Price + Margin + Service Fee) * ({priceCurrencyCode} / {costPriceCurrencyCode})
                </Typography>
                <Typography variant="body2">
                  = ({costPrice} + {margin} + {leServiceFee}) * ({customerExchangeRate} /{vendorExchangeRate})
                </Typography>
                <Typography variant="body2">= {calculateCustomerPriceTotalAndCurrencyConvert()}</Typography>
              </Stack>
            }
            placement="right"
          >
            <IconButton>
              <HelpIcon />
            </IconButton>
          </HtmlTooltip>
        </Grid>
      </Grid>

      <Box mt={2}>
        <Typography variant="h6">Customer payments (Payment made by customer)</Typography>
      </Box>

      <Grid mt={2} container columns={6} spacing={2}>
        <Grid xs={3} md={1}>
          <Controller
            name="payment_metadata.payment_type"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Payment type"
                disabled={hasOrders || isSubmitting}
                select
                fullWidth
                helperText={errors.payment_metadata?.payment_type?.message}
                error={!!errors?.payment_metadata?.payment_type}
              >
                {brand !== LE_BUSINESS_TRAVELLER && <MenuItem value="deposit">Deposit</MenuItem>}
                <MenuItem value="full_payment">Full Payment</MenuItem>
              </TextField>
            )}
          />
        </Grid>
        <Grid xs={6} md={1}>
          <Controller
            name="payment_metadata.block_customer_payment_notifications"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label="Don't notify customer on payment"
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid mt={2} container columns={6} spacing={2}>
        {paymentType == 'deposit' && (
          <>
            <Grid xs={3} md={1}>
              <TextField
                {...register('payment_metadata.customer_deposit_1_amount')}
                helperText={errors.payment_metadata?.customer_deposit_1_amount?.message}
                error={!!errors.payment_metadata?.customer_deposit_1_amount}
                disabled={hasOrders || isSubmitting}
                label="Deposit amount (customer)"
                type="number"
                inputProps={{ step: 0.01 }}
                fullWidth
              />
            </Grid>

            <Grid xs={3} md={1}>
              <Controller
                name="payment_metadata.customer_deposit_1_due_date"
                control={control}
                render={({ field: { ref: _, ...field }, fieldState: { error } }) => (
                  <DateWidget
                    {...field}
                    slotProps={{ textField: { helperText: error?.message, error: !!error } }}
                    disabled={hasOrders || isSubmitting}
                    label="Deposit 1 due date"
                    disablePast
                  />
                )}
              />
            </Grid>
            <Grid xs={3} md={1}>
              <Controller
                name="payment_metadata.deposit_pi_number_customer"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    helperText={error?.message}
                    error={!!error}
                    disabled={isSubmitting}
                    label="Deposit PI number"
                    fullWidth
                    type="text"
                  />
                )}
              />
            </Grid>
            <Grid xs={3} md={1}>
              <Controller
                name="payment_metadata.customer_deposit_payment_made"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label="Deposit payment made"
                  />
                )}
              />
            </Grid>
          </>
        )}
      </Grid>

      <Grid mt={2} container columns={6} spacing={2}>
        <Grid xs={3} md={1}>
          <TextField
            {...register('payment_metadata.final_amount_customer')}
            helperText={errors.payment_metadata?.final_amount_customer?.message}
            error={!!errors.payment_metadata?.final_amount_customer}
            disabled
            label="Final amount (customer)"
            type="number"
            fullWidth
          />
        </Grid>
        <Grid xs={3} md={1}>
          <Controller
            name="due_date"
            control={control}
            render={({ field: { ref: _, ...field }, fieldState: { error } }) => (
              <DateWidget
                {...field}
                slotProps={{ textField: { helperText: error?.message, error: !!error } }}
                disabled={hasOrders || isSubmitting}
                label="Final Amount due date - customer"
                disablePast
              />
            )}
          />
        </Grid>
        {isEditMode && (
          <Grid xs={3} md={1}>
            <TextField
              disabled
              fullWidth
              value={paymentDate ? formatDateWithClock(paymentDate) : 'N/A'}
              label="Customer Paid Full Amount On"
            />
          </Grid>
        )}
      </Grid>

      <Grid mt={2} container columns={6} spacing={2}>
        <Grid xs={6}>
          <TextField
            {...register('notes')}
            helperText={errors.notes?.message}
            error={!!errors.notes}
            disabled={isSubmitting}
            label="Internal notes"
            minRows={2}
            fullWidth
            multiline
          />
        </Grid>
      </Grid>

      <ImagesForm
        images={images}
        hasHiddenToggle={false}
        showSaveButton={false}
        onAddImage={onAddImage}
        onUpdateImages={onUpdateImages}
        onDeleteImage={onDeleteImage}
      />

      {order?.id_orders && (
        <OrderDetailNotes
          orderId={order?.id_orders}
          customer={user}
          refreshData={() => {
            setTimeout(() => window.location.reload(), 200);
          }}
          offerId={offer?.id}
        />
      )}

      <AccountsPayable control={control} isSubmitting={isSubmitting} setValue={setValue} />

      {isEditMode && (
        <Box mt={2}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={inclusionsDiscussed}
                  onChange={(e) => setInclusionsDiscussed(e.target.checked)}
                  disabled={hasOrders || isSubmitting}
                />
              }
              label="Inclusions discussed"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsExplained}
                  onChange={(e) => setTermsExplained(e.target.checked)}
                  disabled={hasOrders || isSubmitting}
                />
              }
              label="Terms and Conditions explained"
            />
          </FormGroup>
        </Box>
      )}

      <Stack mt={2} direction="row" justifyContent="space-between" spacing={2}>
        <Stack direction="row" spacing={2}>
          <Button type="submit" startIcon={<SaveIcon />} variant="contained" disabled={isActionsDisabled}>
            Save
          </Button>

          {showSendButton && (
            <Button
              type="button"
              startIcon={<CheckIcon />}
              variant="contained"
              color="secondary"
              disabled={isSendDisabled}
              onClick={onSendPayment}
            >
              Confirm and show in my escapes
            </Button>
          )}
        </Stack>
        {offer?.status === 'draft' && (
          <Stack direction="row" spacing={2}>
            <Button
              type="button"
              variant="contained"
              color="error"
              disabled={isActionsDisabled}
              onClick={openDeleteDialog}
            >
              Delete
            </Button>
          </Stack>
        )}
        <ConfirmDeleteDialog
          isOpen={deleteDialogOpen}
          description={offer?.name}
          handleClose={closeDeleteDialog}
          handleDelete={deleteOffer}
        />
      </Stack>
    </form>
  );
}
