import qs from 'qs';

import { paths } from '@luxuryescapes/contract-svc-payment';

import { CreditTypes, Credits, ReconciledCredits } from '~/components/Users/Credits/types';

import { CREDIT_TYPES } from '~/consts/credits';

import { PaymentsResult } from '~/types/services/payment';

import { json_headers, request } from './common';

function basePath() {
  return window.configs.API_HOST + '/api/';
}

function basePathV2() {
  return window.configs.API_HOST + '/api/payments/v2/';
}

function delRequest(path) {
  return request(path, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  });
}

function postRequest(path, data) {
  return request(path, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  });
}

function patchRequest(path, data) {
  return request(path, {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  });
}

export function createPayPalTransaction(data) {
  return postRequest(basePath() + 'braintree/checkout', data);
}

export async function createStripeTransaction(data) {
  return postRequest(basePath() + 'stripe/checkout', data);
}

export async function createPendingLatitudeTransaction(data) {
  return postRequest(basePath() + 'latitude/checkout/initiate', data);
}

export async function deleteAccountingMeta(order_id, meta_id) {
  return delRequest(basePath() + 'refund_meta/' + order_id + '/meta/' + meta_id);
}

export async function createLeCreditTransaction(data) {
  return postRequest(basePath() + 'buy_with_credit/checkout', data);
}

export async function createPromoTransaction(data) {
  return postRequest(basePath() + 'promo/checkout', data);
}

export async function getPayPalCredentials() {
  const response = await request(basePath() + 'braintree/credentials');
  return response;
}

export function getStripeCredentials() {
  return request(basePath() + 'stripe/credentials');
}

export function getLatitudeCredentials() {
  return request(basePath() + 'latitude/credentials');
}

export function getStripeReconciliation() {
  return request(basePath() + 'reconcile/stripe');
}

export function getStripeReconciliationOrderId() {
  return request(basePath() + 'reconcile/stripe/orderids');
}

export function getStripeReconciliationCurrencies() {
  return request(basePath() + 'reconcile/stripe/currencies');
}

export function getStripeReconciliationAmounts() {
  return request(basePath() + 'reconcile/stripe/amounts');
}

export function healthCheck() {
  return request(basePath() + 'payment/health');
}

export function getCreditTypes(id_user): CreditTypes {
  const result = CREDIT_TYPES;

  // make Test Credit type available/visible to one test account
  if (id_user === window.configs.TEST_CREDIT_TYPE_ACCOUNT_ID) {
    result.push({ creditType: 'Test Credit' });
  }

  return result;
}

export function getCredits(id_user, currency_in = 'AUD', brand, page?: number): Promise<Credits> {
  let finalPage: number | undefined = undefined;
  if (page === null) {
    // if page is null, we want all options
    finalPage = undefined;
  } else if (!page) {
    // if page isn't passed at all, we want default argument of 1
    finalPage = 1;
  } else {
    // otherwise use the real value
    finalPage = page;
  }
  const queryParams = {
    id_member: id_user,
    currency: currency_in,
    brand,
    page: finalPage,
  };

  return request(`${basePath()}credits/credit_transactions?${qs.stringify(queryParams)}`, { method: 'GET' });
}

export async function addCredit(object) {
  const jsonData = JSON.stringify(object);
  return request(basePath() + 'credits', {
    method: 'POST',
    headers: json_headers,
    body: jsonData,
  });
}

export async function changeExpiryDate(object) {
  return postRequest(basePath() + 'credits/expires', object);
}

export async function bulkCreditsReactivation(file) {
  const data = new FormData();
  data.append('csv', file);

  return request(basePath() + 'credits/bulk/reactivate', {
    method: 'POST',
    body: data,
  });
}

export function getPaymentCurrencies() {
  return request(basePath() + 'payments/currencies', { method: 'GET' });
}

export async function getPayments(id_orders): Promise<PaymentsResult> {
  const url = `${basePath()}payments?id_orders=${id_orders}`;
  return request(url);
}

export async function getMerchantFeeDetails(id_orders) {
  const url = `${basePath()}payments/v2/merchant-fees/orders/${id_orders}/details`;
  return request(url);
}

export function getRefundMeta(id_orders) {
  const url = `${basePath()}refund_meta/${id_orders}`;
  return request(url);
}

export async function getPaymentPlans(id_orders) {
  try {
    const result = await request(basePath() + `payment_plan?order_id=${id_orders}`);
    const plans = result.result.plan;
    let error_logs = [];
    for (const plan of plans) {
      const logs = await request(basePath() + `payment_errors/${plan.payment_plan_component_id}`);
      error_logs = logs.result.error_logs || [];
    }
    const out = {
      plans,
      error_logs,
    };
    return out;
  } catch (e) {
    console.warn(e);
    return { plans: [], error_logs: [] };
  }
}

export function getBraintreeReconciliation() {
  return request(basePath() + 'reconcile/braintree');
}

export function getBraintreeReconciliationOrderId() {
  return request(basePath() + 'reconcile/braintree/orderids');
}

export function getBraintreeReconciliationCurrencies() {
  return request(basePath() + 'reconcile/braintree/currencies');
}

export function getBraintreeReconciliationAmounts() {
  return request(basePath() + 'reconcile/braintree/amounts');
}

export function callDepositTriggers(action, object) {
  return postRequest(basePath() + 'stripe/deposit/trigger/' + action, object);
}

export function callInstalmentTriggers(action, object) {
  return postRequest(basePath() + 'stripe/instalment/trigger/' + action, object);
}

export function callReserveForZeroTriggers(action, object) {
  return postRequest(basePath() + 'stripe/deferred-payment/trigger/' + action, object);
}

export function paymentScheduleChargeSimulation(action, object) {
  return postRequest(basePathV2() + 'payment-schedules/trigger/' + action, object);
}

export function getDepositDetails(orderId) {
  return request(basePath() + 'payments/deposit-details/' + orderId);
}

export function getInstalmentDetails(orderId) {
  return request(basePath() + 'payments/instalment-details/' + orderId);
}

export function getReserveForZeroDetails(orderId) {
  return request(basePath() + 'payments/deferred-payment-details/' + orderId);
}

interface FeeDetails {
  type: 'percentage' | 'fixed_amount';
  value: number;
  refundable: boolean;
}
interface RetryDetails {
  max_charge_attempts: number;
  job_retry_attempts: number;
}

export enum PaymentPlanItem {
  ACCOMMODATION_TYPE = 'accommodation',
  TOUR_OFFER_TYPE = 'tour',
  TOUR_V2_OFFER_TYPE = 'tour_v2',
  TOUR_OPTIONAL_EXPERIENCE_TYPE = 'tour_optional_experience',
  SERVICE_FEE_TYPE = 'service_fee',
  FLIGHTS_ITEM_TYPE = 'flight',
  CUSTOM_OFFER_TYPE = 'custom_offer',
  INSURANCE_TYPE = 'insurance',
  CRUISE_TYPE = 'cruise',
  SUBSCRIPTION_TYPE = 'subscription',
  ADDON_TYPE = 'addon',
  EXPERIENCE_TYPE = 'experience',
  CAR_HIRE_TYPE = 'car_hire',
  BOOKING_PROTECTION_TYPE = 'booking_protection',
  PREPAID_REQUEST_TYPE = 'prepaid_request',
}

export type PaymentPlanItemType = `${PaymentPlanItem}`;

export interface Item {
  id_items: string;
  type: PaymentPlanItemType;
}

export interface PaymentScheduleDetail {
  id: string;
  status: string;
  payment_type: string;
  total_amount: number;
  initial_total_amount: number;
  fee_amount: number;
  base_amount: number;
  due_date: string; // "2024-12-03"
  fee?: FeeDetails;
  amount_type: 'percentage' | 'fixed_amount';
  unit_of_sale?: 'per_item' | 'per_pax';
  retry: RetryDetails;
  failure_reason?: string;
  transaction_key: string;
  paid_date: string;
  items: Array<Item>;
  next_charge_date?: string;
}

interface PaymentSchedulesDetails {
  id: string;
  total_amount: number;
  initial_total_amount: number;
  total_paid: number;
  balance_amount: number;
  status: string;
  brand: string;
  currency: string;
  fk_orders: string;
  fk_customers: string;
  total_service_fee: number;
  payment_schedules: Array<PaymentScheduleDetail>;
  payment_method_id: string;
  payment_method: string;
}

export interface PaymentScheduleDetailsResponse {
  status?: number;
  message?: string;
  result?: PaymentSchedulesDetails;
}

export function getPaymentScheduleDetails(orderId): Promise<PaymentScheduleDetailsResponse> {
  return request(basePathV2() + 'payment-schedules-state/' + orderId);
}

export async function getReconciledCredits(memberId, brand, currency): Promise<ReconciledCredits> {
  const url = `${basePathV2()}credit/reconcile/member/${memberId}?brand=${brand}&currency=${currency}`;
  const results = await request(url);
  if (results.status === 200) {
    return results.result;
  }
  return null;
}

export type ExpireCreditsByAmountProps = {
  brand: string;
  currency: string;
  memberId: string;
  expirationDate: string;
  creditsAdjustmentAmount: number;
  comments: string;
  creditIdsToAdjustExpiration: Array<string>;
};

export async function changeCreditExpiryByAmount(payload: ExpireCreditsByAmountProps) {
  const url = `${basePathV2()}credit/extend-expiry`;
  return postRequest(url, payload);
}

export type LinkManualStripePaymentResult =
  paths['/api/payments/v2/stripe/payment-intents/{paymentIntentId}']['patch']['responses']['200']['content']['application/json'];
export type LinkManualStripePaymentRequest =
  paths['/api/payments/v2/stripe/payment-intents/{paymentIntentId}']['patch']['parameters']['body']['payload'];

export interface LinkManualStripePaymentResponse {
  body: LinkManualStripePaymentResult;
}

export async function linkManualStripePayment(
  paymentIntentId: string,
  payload: LinkManualStripePaymentRequest,
): Promise<LinkManualStripePaymentResponse> {
  const url = `${basePathV2()}stripe/payment-intents/${paymentIntentId}`;
  return await patchRequest(url, payload);
}

export type GetCreditPaymentWithSourceResponse =
  paths['/api/payments/v2/credit/credit-payments/orders/{orderId}']['get']['responses']['200']['content']['application/json'];
export type CreditPaymentsWithSource = GetCreditPaymentWithSourceResponse['result']['creditPayments'];

export function getCreditPaymentsWithSource(orderId: string): Promise<GetCreditPaymentWithSourceResponse> {
  return request(basePathV2() + `credit/credit-payments/orders/${orderId}`);
}

export function getDisputeDetails(orderId) {
  return request(basePathV2() + 'payments/disputes/' + orderId);
}
