import React from 'react';

import { useSnackbar } from 'notistack';

import { ContentCopy } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import FlightIcon from '@mui/icons-material/Flight';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Button, Chip, IconButton, Paper, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { Rate, RoomRates } from '~/services/AccommodationService';

import CancellationPolicyModal from './CancellationPolicyModal';
import MealPlanChip from './MealPlanChip';
import RateDetailsModal from './RateDetailsModal';
import RateInclusionsModal from './RateInclusionsModal';
import RoomFacilitiesModal from './RoomFacilitiesModal';

export default function AccommodationPropertyRatesResultsTable({
  roomRates,
  openModal,
}: {
  roomRates: RoomRates;
  openModal: (title: string, content: React.ReactNode) => void;
}) {
  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();

  const handleCancellationPolicyClick = (rate: Rate) => {
    openModal('Cancellation Policy Details', <CancellationPolicyModal rate={rate} />);
  };

  const handleInclusionsClick = (inclusions: Rate['inclusions']) => {
    openModal('Rate Inclusions', <RateInclusionsModal inclusions={inclusions} />);
  };

  const handleFacilitiesClick = (rate: Rate, roomName: string) => {
    openModal('Room Facilities', <RoomFacilitiesModal rate={rate} roomName={roomName} />);
  };

  const handleRateDetailsClick = (rate: Rate) => {
    openModal('Rate Details', <RateDetailsModal rate={rate} />);
  };

  const isPackaged = (meta: Rate['meta']) => {
    if (!meta) {
      return false;
    }
    return 'packaged' in meta ? meta.packaged : false;
  };

  const calculatePropertyFeesTotal = (propertyFees: Array<Rate['propertyFees'][number]> | undefined) => {
    if (!propertyFees || propertyFees.length === 0) {
      return 0;
    }
    return propertyFees.reduce((total, fee) => total + (fee.amount || 0), 0);
  };

  const renderCancellationPolicyBadge = (rate: Rate) => {
    if (rate.fullRefundableUntil) {
      return (
        <Chip label="Refundable" color="success" size="small" onClick={() => handleCancellationPolicyClick(rate)} />
      );
    } else if (rate.cancellationPolicies && rate.cancellationPolicies.length > 0) {
      return (
        <Chip
          label="Penalties Apply"
          color="warning"
          size="small"
          onClick={() => handleCancellationPolicyClick(rate)}
        />
      );
    } else {
      return (
        <Chip label="Non-refundable" color="default" size="small" onClick={() => handleCancellationPolicyClick(rate)} />
      );
    }
  };

  const renderInclusionsBadge = (inclusions: Rate['inclusions']) => {
    if (!inclusions || inclusions.length === 0) {
      return (
        <Button size="small" variant="text" color="primary" onClick={() => handleInclusionsClick(inclusions)}>
          No inclusions
        </Button>
      );
    }

    return (
      <Button
        size="small"
        variant="text"
        color="primary"
        onClick={() => handleInclusionsClick(inclusions)}
        startIcon={<InfoIcon />}
      >
        View Inclusions
      </Button>
    );
  };

  const renderMetaBadge = (meta: Rate['meta']) => {
    if (!meta) {
      return null;
    }

    const isPLP = JSON.stringify(meta).toLowerCase().includes('bedbank');

    return (
      <Tooltip title={isPLP ? 'Luxury Property Partner' : 'Luxury Property Collection'}>
        <Chip label={isPLP ? 'Bedbank' : 'LPC'} color={isPLP ? 'secondary' : 'primary'} size="small" sx={{ mr: 1 }} />
      </Tooltip>
    );
  };

  const formatPrice = (price: number | undefined) => {
    if (price === undefined || price === null) {
      return 'N/A';
    }
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price);
  };

  const handleCopyClick = (id: string) => {
    navigator.clipboard.writeText(id);
    enqueueSnackbar(`Copied to clipboard`, { variant: 'success' });
  };

  // Define columns for DataGrid
  const columns: Array<GridColDef> = [
    {
      field: 'rateType',
      headerName: 'Rate Type',
      flex: 1,
      renderCell: (params) => <Box>{renderMetaBadge(params.row.meta)}</Box>,
    },
    {
      field: 'flights',
      headerName: 'Flights',
      flex: 0.75,
      renderCell: (params) => (
        <Box>
          {isPackaged(params.row.meta) && (
            <Tooltip title="This rate includes a flight bundle">
              <Chip icon={<FlightIcon />} label="Bundle" size="small" color="info" sx={{ mt: 0.5 }} />
            </Tooltip>
          )}
        </Box>
      ),
    },
    {
      field: 'mealPlan',
      headerName: 'Meal Plan',
      flex: 1.4,
      renderCell: (params) => <MealPlanChip boardCode={params.row.boardCode} openModal={openModal} />,
    },
    {
      field: 'cancellation',
      headerName: 'Cancellation',
      flex: 1,
      renderCell: (params) => renderCancellationPolicyBadge(params.row),
    },
    {
      field: 'inclusions',
      headerName: 'Inclusions',
      flex: 1.5,
      renderCell: (params) => renderInclusionsBadge(params.row.inclusions),
    },
    {
      field: 'bonusInclusions',
      headerName: 'Bonus Inc',
      flex: 0.75,
      minWidth: 140,
      renderCell: (params) =>
        params.row.bonusInclusions.length > 0 ? (
          <Chip icon={<CheckCircleIcon />} label="Yes" size="small" />
        ) : (
          <Chip icon={<CloseIcon />} label="No" size="small" />
        ),
    },
    {
      field: 'basePrice',
      headerName: 'Base Price',
      flex: 1,
      renderCell: (params) => formatPrice(params.row.total?.beforeTaxesFeesPrice as number),
    },
    {
      field: 'taxesAndFees',
      headerName: 'Taxes & Fees',
      flex: 1,
      renderCell: (params) => formatPrice(params.row.total?.taxesAndFees as number),
    },
    {
      field: 'propertyFees',
      headerName: 'Payable at Property',
      flex: 1.2,
      minWidth: 150,
      renderCell: (params) => {
        const hasPropertyFees = params.row.propertyFees && params.row.propertyFees.length > 0;
        const total = calculatePropertyFeesTotal(params.row.propertyFees);
        return hasPropertyFees ? formatPrice(total) : 'None';
      },
    },
    {
      field: 'totalPrice',
      headerName: 'Total Price',
      flex: 1,
      renderCell: (params) => {
        const propertyFeesTotal = calculatePropertyFeesTotal(params.row.propertyFees);
        return formatPrice((params.row.total?.sellingPrice || 0) + propertyFeesTotal);
      },
    },
    {
      field: 'savings',
      headerName: 'Savings',
      flex: 1,
      renderCell: (params) => {
        return params.row.total?.discount > 0 ? (
          <Chip label={`${(params.row.total.discount * 100).toFixed(0)}% off`} color="secondary" size="small" />
        ) : (
          'No discount'
        );
      },
    },
    {
      field: 'details',
      headerName: 'Details',
      flex: 1,
      renderCell: (params) => (
        <IconButton size="small" color="primary" onClick={() => handleRateDetailsClick(params.row)}>
          <InfoIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      {Object.entries(roomRates).map(([roomId, room]) => (
        <Paper key={roomId} sx={{ mb: 3, overflow: 'hidden' }}>
          <Box sx={{ p: 2, bgcolor: theme.palette.grey[100] }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="column" alignItems="flex-start">
                <Typography variant="h6">{room.name}</Typography>
                <Typography variant="subtitle2">
                  ID: {roomId}
                  <IconButton title="Copy Room ID" onClick={() => handleCopyClick(roomId)}>
                    <ContentCopy fontSize="small" />
                  </IconButton>
                </Typography>
              </Stack>
              <Button size="small" variant="outlined" onClick={() => handleFacilitiesClick(room.rates?.[0], room.name)}>
                View Facilities
              </Button>
            </Stack>
          </Box>

          {room.rates?.length > 0 && (
            <DataGrid
              rows={room.rates || []}
              columns={columns}
              getRowId={(row) => row.id}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10, 20]}
              disableColumnFilter
              disableColumnMenu
              disableDensitySelector
              disableColumnSelector
              hideFooterSelectedRowCount
            />
          )}
          {room.rates?.length === 0 && (
            <Box sx={{ p: 2, bgcolor: theme.palette.grey[100], textAlign: 'center' }}>
              <Typography variant="subtitle1">No rates found for this room</Typography>
            </Box>
          )}
        </Paper>
      ))}
    </>
  );
}
