import React from 'react';

import { theme } from '~/theme';

import { Box, Chip, Grid, Stack, Typography } from '@mui/material';

import { RoomRates } from '~/services/AccommodationService';

import MealPlanChip from './MealPlanChip';

type RoomRate = RoomRates[string];
type Rate = RoomRate['rates'][0];

interface BestAvailableRateProps {
  rate: Rate;
  roomName: string;
  openModal: (title: string, content: React.ReactNode) => void;
}

const formatPrice = (price: number) => {
  if (price === undefined || price === null) {
    return 'N/A';
  }
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price);
};

type Refundability = {
  label: string;
  color: string;
};
const getRefundability = (rate: Rate): Refundability => {
  if (rate.fullRefundableUntil) {
    return {
      label: 'Refundable',
      color: theme.palette.success.main,
    };
  }
  if (rate.cancellationPolicies && rate.cancellationPolicies.length > 0) {
    return {
      label: 'Penalties Apply',
      color: theme.palette.warning.main,
    };
  }
  return {
    label: 'Non-refundable',
    color: theme.palette.grey[700],
  };
};

export default function BestAvailableRate({ rate, roomName, openModal }: BestAvailableRateProps) {
  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        backgroundColor: theme.palette.primary.light,
        padding: 2,
        borderRadius: 1,
      }}
    >
      <Typography variant="h6" gutterBottom fontWeight="bold" sx={{ color: theme.palette.primary.contrastText }}>
        Best Available Rate - {roomName}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={3}>
          <Typography variant="h5" fontWeight="bold" sx={{ color: theme.palette.primary.contrastText }}>
            {formatPrice(rate.total.displayPrice)}
          </Typography>
          {rate.total.displayPrice !== rate.total.sellingPrice && (
            <Typography variant="caption" display="block" sx={{ color: theme.palette.primary.contrastText }}>
              = {rate.total.sellingPrice}
              {rate.total.propertyFees && ` + ${formatPrice(rate.total.propertyFees)} at property`}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="subtitle2" sx={{ color: theme.palette.primary.contrastText, opacity: 0.9 }}>
            Room
          </Typography>
          <Typography sx={{ color: theme.palette.primary.contrastText }}>{rate.roomId}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="subtitle2" sx={{ color: theme.palette.primary.contrastText, opacity: 0.9 }}>
            Meal Plan
          </Typography>
          <Box sx={{ mt: 0.5 }}>
            <MealPlanChip boardCode={rate.boardCode} openModal={openModal} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="subtitle2" sx={{ color: theme.palette.primary.contrastText, opacity: 0.9 }}>
            Cancellation
          </Typography>
          <Box sx={{ mt: 0.5 }}>
            <Chip
              label={getRefundability(rate).label}
              sx={{
                bgcolor: getRefundability(rate).color,
                color: theme.palette.getContrastText(getRefundability(rate).color),
                fontWeight: 'bold',
              }}
              size="small"
            />
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
}
