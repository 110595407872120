import React, { memo } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

interface Props {
  item: App.OrderTourItem;
}

function TourOverview({ item }: Props) {
  return (
    <Accordion variant="outlined">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>Overview</AccordionSummary>
      <AccordionDetails>
        <Typography>{item.description}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}

export default memo(TourOverview);
