export const PAYPAL_PAYMENT_TYPE = 'paypal';
export const STRIPE_PAYMENT_TYPE = 'stripe';
export const STRIPE_3DS_PAYMENT_TYPE = 'stripe_3ds';
export const STRIPE_PAYMENT_ELEMENT_CARD_PAYMENT_TYPE = 'stripe_payment_element_card';
export const CREDIT_PAYMENT_TYPE = 'credit';
export const PROMO_PAYMENT_TYPE = 'promo';
export const BRAINTREE_PAYMENT_TYPE = 'braintree';
export const RAZORPAY_PAYMENT_TYPE = 'razorpay';
export const LATITUDE_PAYMENT_TYPE = 'latitude';
export const LATITUDE_GEM_PAYMENT_TYPE = 'latitude_gem';
export const LATITUDE_PAY_PAYMENT_TYPE = 'latitude_pay';
export const KLARNA_PAYMENT_TYPE = 'klarna';
export const KLARNA_BP_PAYMENT_TYPE = 'klarna_bp';
export const BRIDGER_PAY_PAYMENT_TYPE = 'bridgerpay';
export const AFTER_PAY_BP_PAYMENT_TYPE = 'afterpay_bp';
export const HOOLAH_BP_PAYMENT_TYPE = 'hoolah_bp';
export const ATOME_BP_PAYMENT_TYPE = 'atome_bp';
export const WAAVE_BP_PAYMENT_TYPE = 'waave_bp';
export const QANTAS_PAYMENT_TYPE = 'qantas';
export const APPLE_PAY_PAYMENT_TYPE = 'applepay';
export const GOOGLE_PAY_PAYMENT_TYPE = 'googlepay';
export const FLIGHTS_CREDIT_PAYMENT_TYPE = 'flightcredits';

export const LATITUDE_ALL_TYPES = [LATITUDE_PAYMENT_TYPE, LATITUDE_GEM_PAYMENT_TYPE, LATITUDE_PAY_PAYMENT_TYPE];

export const PAYMENT_METHODS_NOT_ALLOWED_REFUND = [LATITUDE_PAYMENT_TYPE, LATITUDE_GEM_PAYMENT_TYPE];

export const PaymentsServiceProviders = [
  {
    title: 'Luxury Escapes Credit',
    value: 'le_credit',
  },
  {
    title: 'Stripe',
    value: STRIPE_PAYMENT_TYPE,
  },
  {
    title: 'Stripe3ds',
    value: STRIPE_3DS_PAYMENT_TYPE,
  },
  {
    title: 'Stripe (Payment Element Card)',
    value: STRIPE_PAYMENT_ELEMENT_CARD_PAYMENT_TYPE,
  },
  {
    title: 'Paypal',
    value: BRAINTREE_PAYMENT_TYPE,
  },
  {
    title: 'Qantas',
    value: QANTAS_PAYMENT_TYPE,
  },
  {
    title: 'Latitude Gem',
    value: LATITUDE_GEM_PAYMENT_TYPE,
  },
  {
    title: 'LatitudePay',
    value: LATITUDE_PAY_PAYMENT_TYPE,
  },
  {
    title: 'Klarna',
    value: KLARNA_PAYMENT_TYPE,
  },
  {
    title: 'BridgerPay',
    value: BRIDGER_PAY_PAYMENT_TYPE,
  },
  {
    title: 'ApplePay',
    value: APPLE_PAY_PAYMENT_TYPE,
  },
  {
    title: 'GooglePay',
    value: GOOGLE_PAY_PAYMENT_TYPE,
  },
  {
    title: 'Razorpay',
    value: RAZORPAY_PAYMENT_TYPE,
  },
  {
    title: 'Waave (Bridgerpay)',
    value: WAAVE_BP_PAYMENT_TYPE,
  },
  {
    title: 'AfterPay (Bridgerpay)',
    value: AFTER_PAY_BP_PAYMENT_TYPE,
  },
  {
    title: 'Klarna (Bridgerpay)',
    value: KLARNA_BP_PAYMENT_TYPE,
  },
  {
    title: 'Hoolah/Shopback (Bridgerpay)',
    value: HOOLAH_BP_PAYMENT_TYPE,
  },
  {
    title: 'Atome (Bridgerpay)',
    value: ATOME_BP_PAYMENT_TYPE,
  },
];

export const PaymentSchedulePaymentMethodTypes = {
  StripeOffSessionCard: 'stripe_off_session_cards',
  StripeSubscription: 'stripe_subscription',
};
