import React from 'react';

import { Box, Button, ButtonProps } from '@mui/material';

export function EarnButton({ onClick, children, disabled }: ButtonProps) {
  return (
    <Box padding={1}>
      <Button variant="outlined" color="primary" onClick={onClick} disabled={disabled}>
        {children}
      </Button>
    </Box>
  );
}
