import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';

import { Box } from '@mui/material';

import { getLuxLoyaltyOrderPendingPoints, getLuxLoyaltySuccessfulAccrualOnOrder } from '~/services/LuxLoyaltyService';
import { getOrderDetail } from '~/services/OrdersService';
import UsersService from '~/services/UsersService';

import { UserType } from '~/types/responses';

import Spinner from '../../Common/Spinner';
import PageBody from '../../Page/PageBody';
import PageHeaderBar from '../../Page/PageHeaderBar';
import PageLayout from '../../Page/PageLayout';
import PageSidebar from '../../Page/PageSidebar';
import OrderDetailsHeaderActions from '../../Purchases/OrderDetail/OrderDetailsHeaderActions';
import OrderDetailsHeaderTitle from '../../Purchases/OrderDetail/OrderDetailsHeaderTitle';
import OrderDetailsSidebar from '../../Purchases/OrderDetail/OrderDetailsSidebar';

import OrderDetailLuxLoyalty from './OrderDetailLuxLoyalty';

function OrderDetailLuxLoyaltyContainer() {
  const [order, setOrder] = useState<App.Order | undefined>(undefined);
  const [pendingPoints, setPendingPoints] = useState<Array<App.LuxLoyaltyPendingPoint>>([]);
  const [accruals, setAccruals] = useState<Array<App.LuxLoyaltyAccrual>>([]);
  const [customer, setCustomer] = useState<undefined | UserType>(undefined);
  const [isReady, setIsReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { orderId } = useParams<{ orderId: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const refreshData = async () => {
    setIsReady(false);
    setIsLoading(true);
    let order = undefined;
    let pendingPoints: Array<App.LuxLoyaltyPendingPoint> = [];
    let earnAccruals: Array<App.LuxLoyaltyAccrual> = [];
    try {
      order = await getOrderDetail(orderId);
      pendingPoints = (await getLuxLoyaltyOrderPendingPoints(order.fk_customer_id, order.id_orders)).pendingPoints;
      earnAccruals = await getLuxLoyaltySuccessfulAccrualOnOrder(order.id_orders);
      if (order?.fk_customer_id) {
        const customer = await UsersService.getUser(order.fk_customer_id);
        setCustomer(customer);
      }
      setOrder(order);
      setPendingPoints(pendingPoints);
      setAccruals(earnAccruals);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!orderId) {
      return;
    }

    const fetchData = async () => {
      await refreshData();
      setIsReady(true);
    };
    fetchData();
  }, [orderId]);

  if (isLoading || !isReady) {
    return (
      <PageLayout>
        <PageHeaderBar title="Order Societe Points Details" />
        <PageBody>
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
            <Spinner />
          </Box>
        </PageBody>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <PageHeaderBar
        title={<OrderDetailsHeaderTitle order={order} customer={customer} />}
        actions={<OrderDetailsHeaderActions order={order} />}
      />
      <PageSidebar placement="start" width="medium">
        <OrderDetailsSidebar order={order} customer={customer} />
      </PageSidebar>
      <PageBody>
        {!isReady ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
            <Spinner />
          </Box>
        ) : (
          <OrderDetailLuxLoyalty
            payments={order?.payments ?? []}
            pendingPoints={pendingPoints ?? []}
            order={order}
            accruals={accruals ?? []}
          />
        )}
      </PageBody>
    </PageLayout>
  );
}

export default OrderDetailLuxLoyaltyContainer;
