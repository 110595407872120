import { Payment } from '~/types/services/payment';

import featureToggle from './featureToggle';

export function isLuxLoyaltyEnabled(): boolean {
  return featureToggle.availableToShow('SHOW_LUX_LOYALTY');
}

export interface ItemPointsPaymentDetails {
  itemId: string;
  currency: string;
  pointsPaid: number;
  pointsPaidCurrencyValue: number;
  priceInCurrency: number;
  priceInPoints: number;
}

/**
 * Extracts the points payments for an item
 * @param payments The array of payments associated with an order
 * @returns
 */
export function mapPointsPaymentsForItem(
  payments: Array<Payment>,
  itemId: string,
): ItemPointsPaymentDetails | undefined {
  const payment = payments.find((payment) => payment.type === 'le_points' && payment.item_ids.includes(itemId));
  const itemsBreakdown = payment?.items_breakdown?.find((breakdown) => breakdown?.id_items === itemId);
  const pointsDetails = itemsBreakdown?.item_le_points_details;

  if (!pointsDetails) {
    return;
  }

  return {
    itemId: itemsBreakdown.item_le_points_details?.item_id,
    currency: payment.currency,
    pointsPaid: itemsBreakdown.item_le_points_details?.points_paid,
    pointsPaidCurrencyValue: itemsBreakdown.item_le_points_details?.points_paid_currency_value,
    priceInCurrency: itemsBreakdown.item_le_points_details?.price_in_currency,
    priceInPoints: itemsBreakdown.item_le_points_details?.price_in_points,
  };
}

/**
 * Extracts the pending points for an item from all pending points associated with an order
 * @param pendingPoints Pending points returned from request to lux loyalty
 * @param itemId Id of the item for which to find pending points
 * @returns Pending points for the item or undefined
 */
export function mapPendingPointsForItem(
  pendingPoints: Array<App.LuxLoyaltyPendingPoint>,
  itemId: string,
): App.LuxLoyaltyPendingPoint | undefined {
  const pending = pendingPoints.find((pending) => pending.itemId === itemId);
  return pending;
}
export const luxLoyaltyBenefitDescriptionMap: Map<App.LuxLoyaltyBenefitType, string> = new Map([
  ['airport_transfer', 'Airport Transfers'],
  ['discounted_insurance', 'Discounted Travel & Booking Protection'],
  ['early_access', 'Early Access To Deals'],
  ['hidden_access', 'Hidden Access To Deals'],
  ['lounge_pass', 'Lounge Pass Access (AU Airports)'],
  ['phone_support', 'Priority 24/7 Phone Support'],
  ['tour_exp_credit', 'Tour Optional Extras'],
  ['travelshoot_credit', 'Travelshoot Vouchers'],
  ['hotel_upgrade', 'Complimentary Hotel Upgrades'],
]);
