import React from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { formatDateOrdinalShortMonth } from '~/services/TimeService';

import { formatCurrency } from '~/utils/currencyFormatter';
import { ItemPointsPaymentDetails } from '~/utils/luxLoyalty';

interface Props {
  pointsPayment?: ItemPointsPaymentDetails;
  pendingPointsInfo?: App.LuxLoyaltyPendingPoint;
  region: string;
}

const pendingPointsColumns: Array<GridColDef> = [
  {
    field: 'points',
    headerName: 'Points',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueFormatter: (value: number) => value.toLocaleString(),
  },
  {
    field: 'statusCredits',
    headerName: 'Status Credits',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueGetter: (value: number) => value.toLocaleString(),
  },
  {
    field: 'pointsEarnDate',
    headerName: 'Earn Date',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueGetter: (value: string | null) =>
      value ? formatDateOrdinalShortMonth(new Date(value)) : 'Processing (Updated Daily)',
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueGetter: (value: App.LuxLoyaltyPendingPointStatus) =>
      value === 'AWAITING_PAYMENT' ? 'Processing (Updated Daily)' : value,
  },
];

const pointsPaymentColumns: Array<GridColDef> = [
  {
    field: 'pointsPaid',
    headerName: 'Points Paid',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueFormatter: (value: number) => value.toLocaleString(),
  },
  {
    field: 'pointsPaidCurrencyValue',
    headerName: 'Value',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => formatCurrency(params.value, params.row.currency, 'AU'),
  },
  {
    field: 'priceInPoints',
    headerName: 'Price in Points',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueFormatter: (params: GridRenderCellParams) => params.value?.toLocaleString(),
  },
  {
    field: 'priceInCurrency',
    headerName: 'Price in Currency',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => <>{formatCurrency(params.value, params.row.currency, 'AU')}</>,
  },
];

export default function LuxLoyaltyPointsDetails({ pointsPayment, pendingPointsInfo }: Props) {
  const pendingPointsData = pendingPointsInfo ? [pendingPointsInfo] : [];
  const pointsPaymentData = pointsPayment ? [pointsPayment] : [];

  return (
    <Stack spacing={2}>
      {pointsPaymentData.length > 0 && (
        <Box>
          <Typography fontWeight="medium" gutterBottom>
            Points Payment Details
          </Typography>
          <DataGrid
            rows={pointsPaymentData}
            columns={pointsPaymentColumns}
            getRowId={(row) => row.itemId}
            autoHeight
            pageSizeOptions={[]}
            disableColumnMenu
            disableRowSelectionOnClick
            hideFooter
            getRowHeight={() => 'auto'}
          />
        </Box>
      )}

      {pendingPointsData.length > 0 && (
        <Box>
          <Typography fontWeight="medium" gutterBottom>
            Pending Points Details
          </Typography>
          <DataGrid
            rows={pendingPointsData}
            columns={pendingPointsColumns}
            getRowId={(row) => row.itemId}
            autoHeight
            pageSizeOptions={[]}
            disableColumnMenu
            disableRowSelectionOnClick
            hideFooter
            getRowHeight={() => 'auto'}
          />
        </Box>
      )}
    </Stack>
  );
}
