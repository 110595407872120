import React from 'react';

import { Grid, Stack, Typography } from '@mui/material';

import { Payment } from '~/types/services/payment';

import LuxLoyaltyItemAccrualsDetails from './LuxLoyaltyItemAccrualDetails';
import LuxLoyaltyManualEarn from './LuxLoyaltyManualEarn';
import { LuxLoyaltyEarnEligibleOrderItem } from './manualEarn.service';

interface Props {
  order: App.Order;
  itemAccruals: Array<App.LuxLoyaltyAccrual>;
  payments: Array<Payment>;
  item: LuxLoyaltyEarnEligibleOrderItem;
}

/**
 *
 * @deprecated DO NOT USE
 */
export default function LuxLoyaltyEarnBreakdown({ order, itemAccruals, payments, item }: Props) {
  return (
    <Stack margin={2}>
      <Typography fontWeight="bold" fontSize="medium">
        Accrual Details
      </Typography>
      {itemAccruals.length > 0 && (
        <Grid spacing={2} paddingBottom={6}>
          <LuxLoyaltyItemAccrualsDetails accruals={itemAccruals} />
        </Grid>
      )}
      {!itemAccruals.length && <LuxLoyaltyManualEarn order={order} item={item} payments={payments} />}
    </Stack>
  );
}
