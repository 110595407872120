import React from 'react';

import { Box, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

interface PointsEarnResult {
  pending: {
    points: number;
    statusCredits: number;
  };
  estimate: {
    points: number;
    statusCredits: number;
  };
  accrual: {
    points: number;
    statusCredits: number;
  };
}

interface Props {
  pointsBreakdown: PointsEarnResult;
}

const pointsBreakdownColumns: Array<GridColDef> = [
  {
    field: 'type',
    headerName: 'Type',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'points',
    headerName: 'Points',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'statusCredits',
    headerName: 'Status Credits',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
];

export default function LuxLoyaltyManualEarnTable({ pointsBreakdown }: Props) {
  const pointsBreakdownData = [
    {
      id: 'pending',
      type: 'Pending',
      points: pointsBreakdown.pending.points,
      statusCredits: pointsBreakdown.pending.statusCredits,
      valueFormatter: (value: number) => value.toLocaleString(),
    },
    {
      id: 'estimate',
      type: 'Estimate',
      points: pointsBreakdown.estimate.points,
      statusCredits: pointsBreakdown.estimate.statusCredits,
      valueFormatter: (value: number) => value.toLocaleString(),
    },
    {
      id: 'accrual',
      type: 'Accrual',
      points: pointsBreakdown.accrual.points,
      statusCredits: pointsBreakdown.accrual.statusCredits,
      valueFormatter: (value: number) => value.toLocaleString(),
    },
  ];

  return (
    <Box>
      <Typography fontWeight="medium" gutterBottom>
        Points Breakdown
      </Typography>
      <DataGrid
        rows={pointsBreakdownData}
        columns={pointsBreakdownColumns}
        getRowId={(row) => row.id}
        autoHeight
        pageSizeOptions={[]}
        disableColumnMenu
        disableRowSelectionOnClick
        hideFooter
        getRowHeight={() => 'auto'}
      />
    </Box>
  );
}
